import * as yup from 'yup';

export const taskTypeSchema = yup.object().shape({
  color_icon: yup.string().nullable().required('common.fieldRequired'),
  name: yup.string().required('common.fieldRequired'),
  realization_progress_id: yup.string().required('common.fieldRequired'),
  functions_approving_steps_attributes: yup
    .array()
    .of(
      yup
        .object()
        .shape({ function_id: yup.number().required('common.fieldRequired') })
    )
});
