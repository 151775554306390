import {
  ChosenItemsHeader,
  StyledP,
  DetailedHeaderWrapper,
  Wrapper
} from './TaskTable.styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { P } from '../../../../../components/Typography/Typography';
import {
  Avatar,
  Box,
  Flex,
  Image,
  Modal,
  Table
} from '../../../../../components';
import { Button, Checkbox, TextField } from '../../../../../components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCirclePlay,
  faStar,
  faClipboard,
  faTriangleExclamation,
  faSearch,
  faUserPen
} from '@fortawesome/free-solid-svg-icons';
import { DefaultTFuncReturn } from 'i18next';

import { theme } from '../../../../../styles';
import { TaskFormModal } from '../../TaskFormModal/TaskFormModal';
import { CancelTaskModal } from './components/CancelTaskModal';
import { RemindLatecomersModal } from './components/RemindLatecomersModal';
import { SendNotificationsModal } from './components/SendNotificationsModal';
import { Tooltip } from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { StarModal } from '../../TaskFormModal/components/StarModal';
import { ITask } from 'types/task';
import { toast } from 'react-toastify';
import { useAccount } from 'hooks/useAccount';
import { createTask, deleteTask, editTask, getTasks } from 'api/tasks';
import { ITaskForm } from 'types/forms/task';
import { useTaskSidebarContext } from 'contexts/TaskSidebarContext';
import { ColumnsOptions } from 'components/Table/components/ColumnsOptions';
import { debounce } from 'lodash';
import { TopBar } from './components/TopBar';
import { useTaskContext } from 'contexts/TaskContext';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { getRealizationProgresses } from 'api/realizationProgresses';
import { IRealizationProgress } from 'types/realizationProgresses';
import { IUser } from 'types/user';
import { getUsers } from 'api/user';
import { TaskStar } from 'components/TaskStar/TaskStar';
import { differenceInDays, format, isAfter } from 'date-fns';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { durationIso8601ToHour } from 'utilities/durationIso8601ToHour';
import { durationIso8601ToMinute } from 'utilities/durationIso8601ToMinute';

interface Props {
  customTitle?: string;
  score_ids?: number[];
  person_in_charge_ids?: string[];
  team_ids?: string[];
  showTopBar?: boolean;
  showAdditionalHeader?: boolean;
  task_type_kinds?: string[];
  without_active?: boolean;
  without_archived?: boolean;
  without_cancelled?: boolean;
  loadContextFilters?: boolean;
  showDetailedHeader?: boolean;
  nameColMinWidth?: string;
  tableContainerHeight?: string;
  defaultHiddenItems?: string[];
  areItemsSelectable?: boolean;
}

export const TaskTable: React.FC<Props> = ({
  customTitle,
  score_ids,
  person_in_charge_ids,
  team_ids,
  task_type_kinds,
  without_active,
  without_archived,
  without_cancelled,
  loadContextFilters = true,
  showTopBar = true,
  showAdditionalHeader = true,
  showDetailedHeader,
  nameColMinWidth,
  tableContainerHeight,
  defaultHiddenItems = [],
  areItemsSelectable = true
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [hiddenItems, setHiddenItems] = useState<string[]>(defaultHiddenItems);
  const [IsFormModalOpen, setIsFormModalOpen] = useState(false);
  const [IsCancelTaskModalOpen, setIsCancelTaskModalOpen] = useState(false);
  const [IsArchiveTaskModalOpen, setIsArchiveTaskModalOpen] = useState(false);
  const [IsRemindLatecomersModalOpen, setIsRemindLatecomersModalOpen] =
    useState(false);
  const [IsSendNotificationsModalOpen, setIsSendNotificationsModalOpen] =
    useState(false);
  const [isStarModalOpen, setIsStarModalOpen] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState<ITask>();
  const [checkedItemsIds, setCheckedItemsIds] = useState<number[]>([]);
  const [realizationProgresses, setRealizationProgresses] = useState<
    IRealizationProgress[]
  >([]);
  const [usersList, setUsersList] = useState<IUser[]>([]);

  const { filters, setFilters } = useTaskSidebarContext();
  const { tasks, setTasks } = useTaskContext();

  const { loggedUserData } = useAccount();

  const handleSearchOnChange = debounce((e) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        idCodeName: {
          label:
            e.target.value.length > 0
              ? t('profile.idCodeName') + ' - ' + e.target.value
              : '',
          value: e.target.value
        }
      };
    });
  }, 500);

  const handleSubmitTaskForm = async (data: ITaskForm['task']) => {
    try {
      if (loggedUserData?.id) {
        const {
          taskType,
          priority,
          task_participations_attributes,
          realization_statuses_attributes,
          start_time,
          completion_time,
          ...restData
        } = data;

        const actualTaskParticipationsAttributes = selectedItemData
          ? [
              // participants included in fetched list and not in selected list are removed
              ...selectedItemData.task_participations
                .filter(
                  (taskParticipation) =>
                    !task_participations_attributes?.find(
                      (taskParticipationAttribute) =>
                        taskParticipationAttribute.value.toString() ===
                        taskParticipation.participant?.id.toString()
                    )
                )
                .map((taskParticipation) => ({
                  id: taskParticipation.id,
                  _destroy: 1
                })),
              // participants included in selected list and not in fetched list are removed
              ...(
                task_participations_attributes?.filter(
                  (taskParticipationAttribute) =>
                    !selectedItemData.task_participations.find(
                      (taskParticipation) =>
                        taskParticipationAttribute.value.toString() ===
                        taskParticipation.participant.id.toString()
                    )
                ) || []
              ).map((taskParticipationAttribute) => ({
                participant_id: taskParticipationAttribute.value,
                function_id: taskParticipationAttribute.function_id
              })),
              // participants included in both but with different function_id
              ...(
                selectedItemData.task_participations?.filter(
                  (taskParticipation) =>
                    task_participations_attributes?.find(
                      (taskParticipationAttribute) =>
                        taskParticipationAttribute.value.toString() ===
                          taskParticipation.participant?.id.toString() &&
                        taskParticipationAttribute.function_id?.toString() !==
                          taskParticipation.function?.id.toString()
                    )
                ) || []
              ).map((taskParticipation) => ({
                id: taskParticipation.id,
                function_id: task_participations_attributes?.find(
                  (taskParticipationAttribute) =>
                    taskParticipationAttribute.value.toString() ===
                    taskParticipation.participant?.id.toString()
                )?.function_id
              }))
            ]
          : task_participations_attributes?.map(
              (taskParticipationAttribute) => ({
                participant_id: taskParticipationAttribute.value,
                function_id: taskParticipationAttribute.function_id
              })
            );

        const payload = {
          task: {
            ...restData,
            task_type_id: taskType?.value,
            priority_degree_id: priority?.value,
            author_id: loggedUserData.id,
            start_time: start_time
              ? start_time
              : realization_statuses_attributes?.find(
                  (realizationStatusesAttribute) =>
                    realizationStatusesAttribute.kind === 'P'
                )?.confirmed_at,
            completion_time: completion_time
              ? completion_time
              : realization_statuses_attributes?.find(
                  (realizationStatusesAttribute) => {
                    const realizationProgress = realizationProgresses.find(
                      ({ id }) =>
                        id ===
                        realizationStatusesAttribute.realization_progress_id
                    );

                    return (
                      realizationProgress?.kind === 'PDCA' &&
                      realizationStatusesAttribute.kind === 'A'
                    );
                  }
                )?.confirmed_at,
            task_participations_attributes: actualTaskParticipationsAttributes,
            realization_statuses_attributes: [
              ...(realization_statuses_attributes?.filter(
                ({ kind }) =>
                  !selectedItemData?.realization_statuses?.find(
                    (realizationStatus) => realizationStatus.kind === kind
                  )
              ) || []),
              selectedItemData?.realization_statuses
                ?.filter(
                  ({ id }) =>
                    !realization_statuses_attributes?.find(
                      (realizationStatusAttribute) =>
                        'id' in realizationStatusAttribute &&
                        realizationStatusAttribute.id === id
                    )
                )
                .map(({ id }) => ({ id, _destroy: 1 }))
            ]
          }
        };

        if (selectedItemData) {
          const { data: resultData } = await editTask(
            selectedItemData.id,
            payload
          );
          setTasks((prevTasks) =>
            prevTasks.map((prevTask) =>
              prevTask.id === resultData.id ? resultData : prevTask
            )
          );
          setIsFormModalOpen(false);
          toast.success(t('common.success'));
          setSelectedItemData(undefined);
        } else {
          const { data: resultData } = await createTask(payload);

          setTasks((prevTasks) => [...prevTasks, resultData]);
          setIsFormModalOpen(false);
          toast.success(t('common.success'));
          setSelectedItemData(undefined);
        }
      }
    } catch (e) {
      console.error(e);

      toast.error(t('anErrorOccurred'));
    }
  };

  const handleDeleteTask = async () => {
    try {
      if (selectedItemData) {
        await deleteTask(selectedItemData.id);
        setTasks((prevTasks) =>
          prevTasks.filter((prevTask) => prevTask.id !== selectedItemData.id)
        );
        setIsFormModalOpen(false);
        toast.success(t('common.success'));
        setSelectedItemData(undefined);
      }
    } catch (e) {
      toast.error(t('anErrorOccurred'));
    }
  };

  const handleLoadTasks = useCallback(async () => {
    const {
      typesOfTasks,
      taskPriorities,
      teamsAndGroups,
      authors,
      responsiblePersons,
      idCodeName,
      created,
      startDate,
      timeLimitForCompletion,
      implementation,
      hidden,
      starred
      // areas
    } = filters;

    const filtersOrganized = {
      person_in_charge_ids: person_in_charge_ids ? person_in_charge_ids : [],
      score_ids: score_ids ? score_ids : [],
      team_ids: team_ids ? team_ids : [],
      task_type_kinds: task_type_kinds ? task_type_kinds : [],
      without_active: !!without_active,
      without_archived: !!without_archived,
      without_cancelled: !!without_cancelled,

      ...(loadContextFilters && {
        task_type_ids: typesOfTasks.value,
        priority_degree_ids: taskPriorities.value,
        score_ids: score_ids ? score_ids : [],
        team_ids: teamsAndGroups.value,
        author_ids: authors.value,
        person_in_charge_ids: responsiblePersons.value,
        text: idCodeName.value,
        created_at_or_later: created.value.from,
        created_no_later_than: created.value.to,
        started_at_or_later: startDate.value.from,
        started_no_later_than: startDate.value.to,
        planned_completion_for_on_or_after: timeLimitForCompletion.value.from,
        planned_completion_for_no_later_than: timeLimitForCompletion.value.to,
        completed_at_or_later: implementation.value.from,
        completed_no_later_than: implementation.value.to,
        without_active: hidden.value.includes(1),
        without_archived: hidden.value.includes(2),
        without_cancelled: hidden.value.includes(3),
        starred_by_user_ids: starred.value.includes(1)
          ? [loggedUserData.id]
          : [],
        starred_by_teamed_up_ids: starred.value.includes(2)
          ? [loggedUserData.id]
          : [],
        starred_by_area_ids: starred.value.includes(3)
          ? loggedUserData.ownedAreas.map(({ id }) => id)
          : []
        // tmp when api will implement areas filters it propably will be compatible
        // areas_ids: areas.value
      })
    };

    const tasksReponse = await getTasks({
      filters: { ...filtersOrganized, page, per_page: perPage }
    });

    if ('error' in tasksReponse) {
      return;
    }

    setTasks(tasksReponse.data);

    if (tasksReponse.pagination?.count) {
      setPaginationTotal(Number(tasksReponse.pagination.count));
    }
  }, [
    filters,
    loggedUserData.id,
    JSON.stringify(loggedUserData.ownedAreas),
    score_ids,
    person_in_charge_ids,
    team_ids,
    task_type_kinds,
    without_active,
    without_archived,
    without_cancelled,
    page,
    perPage,
    loadContextFilters
  ]);

  const handleLoadRealizationProgresses = async () => {
    const { data } = await getRealizationProgresses();

    setRealizationProgresses(data);
  };

  const handleLoadUsers = async () => {
    const { data } = await getUsers();

    setUsersList(data);
  };

  useEffect(() => {
    handleLoadTasks();
  }, [handleLoadTasks]);

  useEffect(() => {
    handleLoadRealizationProgresses();
    handleLoadUsers();
  }, []);

  const header: (string | JSX.Element | DefaultTFuncReturn)[] = [
    ...(areItemsSelectable
      ? [
          <Checkbox
            key="headerCheckbox"
            checked={tasks.every(({ id }) => checkedItemsIds?.includes(id))}
            onClick={() => {
              if (tasks.every(({ id }) => checkedItemsIds?.includes(id))) {
                setCheckedItemsIds([]);
              } else {
                setCheckedItemsIds(tasks.map(({ id }) => id));
              }
            }}
          />
        ]
      : []),
    <FontAwesomeIcon key="icon-faStar" icon={faStar} />,
    t('tasks.task.table.practice'),
    t('tasks.task.table.name'),
    t('tasks.task.table.taskType'),
    t('tasks.task.table.responsible'),
    t('tasks.task.table.area'),
    <FontAwesomeIcon
      key="icon-faTriangleExclamation"
      icon={faTriangleExclamation}
    />,
    t('tasks.task.table.status'),
    t('tasks.task.table.deadlineRealisation'),
    t('tasks.task.table.timeRealisation'),
    t('tasks.task.table.daysCount'),
    ''
  ];

  const rowButtons = ({ item }: { item: ITask }) => (
    <Flex style={{ gap: '10px' }} mx="auto" width="fit-content">
      <Button
        variant="green"
        onClick={() => {
          console.log(111);
        }}
        icon={<FontAwesomeIcon icon={faCirclePlay} />}
      />
      <Button
        variant="grey"
        bordered
        onClick={() => {
          console.log(222);
        }}
        icon={<FontAwesomeIcon icon={faClipboard} />}
      />
      <Button
        onClick={() => {
          setIsFormModalOpen((prevIsFormModalOpen) => !IsFormModalOpen);
          setSelectedItemData(item);
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />
    </Flex>
  );

  const parseData = (data: ITask[]) => {
    return data.map((el) => {
      return {
        ...(areItemsSelectable && {
          checkbox: (
            <Checkbox
              checked={checkedItemsIds?.includes(el.id)}
              onClick={() => {
                if (checkedItemsIds?.includes(el.id)) {
                  setCheckedItemsIds((prevCheckedItemsIds) =>
                    prevCheckedItemsIds.filter(
                      (prevCheckedItemsId) => prevCheckedItemsId !== el.id
                    )
                  );
                } else {
                  setCheckedItemsIds((prevCheckedItemsIds) => [
                    ...prevCheckedItemsIds,
                    el.id
                  ]);
                }
              }}
            />
          )
        }),
        star: (
          <>
            <Button
              variant="secondary"
              transparent
              id={'starTooltip' + el.id}
              data-tooltip-content={t('tasks.task.table.star') || ''}
              icon={<TaskStar task={el} />}
              onClick={() => {
                setSelectedItemData(el);
                setIsStarModalOpen(
                  (prevIsStarModalOpen) => !prevIsStarModalOpen
                );
              }}
            />
            <Tooltip anchorId={'starTooltip' + el.id} />
          </>
        ),
        practice: (
          <>
            <Flex justifyContent="center">
              <Image
                id={'practiceTooltip' + el.id}
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <Flex flexDirection="column" alignItems="center">
                    <StyledP variant="body">{el.id}</StyledP>
                    <StyledP variant="body">{el.name}</StyledP>
                    <StyledP variant="body">
                      <FontAwesomeIcon icon={faUserPen} /> {el.author.email}
                    </StyledP>
                  </Flex>
                )}
              />
              <StyledP variant="body">{el.practice?.name}</StyledP>
            </Flex>
            <Tooltip anchorId={'practiceTooltip' + el.id} />
          </>
        ),
        name: (
          <Box
            minWidth={nameColMinWidth ? nameColMinWidth : '300px'}
            onClick={() => {
              setIsFormModalOpen((prevIsFormModalOpen) => !IsFormModalOpen);
              setSelectedItemData(el);
            }}
          >
            <StyledP variant="body">{el.name}</StyledP>
          </Box>
        ),
        taskType: <StyledP variant="body">{el.task_type?.name}</StyledP>,
        responsible: (
          <>
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              id={'responsibleTooltip' + el.id}
              data-tooltip-content={
                t('tasks.task.table.tooltips.responsible') || ''
              }
            >
              <Avatar size="extra-small" />
              <Box ml={2}>
                <StyledP variant="body">{el.author.username}</StyledP>
              </Box>
            </Flex>
            <Tooltip anchorId={'responsibleTooltip' + el.id} />
          </>
        ),
        area: (
          <StyledP variant="body">
            {el.areas.map(({ name }) => name).join(', ')}
          </StyledP>
        ),
        priority: el.priority_degree?.image_url ? (
          <>
            <img
              width="30px"
              height="30px"
              src={
                process.env.REACT_APP_API_URL + el.priority_degree?.image_url
              }
              alt="priority symbol"
              id={'priorityTooltip' + el.id}
              data-tooltip-content={el.priority_degree?.name}
            />
            <Tooltip anchorId={'priorityTooltip' + el.id} />
          </>
        ) : (
          <StyledP variant="body">{el.priority_degree?.name}</StyledP>
        ),
        status: (
          <Flex justifyContent="center">
            <Box>
              <RealizationProgress
                id={`${el.id}-RealizationProgress`}
                realizationProgress={realizationProgresses.find(
                  ({ id }) =>
                    id === el.realization_statuses?.[0]?.realization_progress_id
                )}
                realizationStatusAttributes={el.realization_statuses}
                usersList={usersList}
              />
            </Box>
          </Flex>
        ),
        deadlineRealisation: (
          <div
            style={{
              background:
                el.completion_time &&
                el.planned_completion_date &&
                !!isAfter(
                  new Date(el.completion_time),
                  new Date(el.planned_completion_date)
                )
                  ? theme.palette.accent.lightRed
                  : '',
              padding: '8px'
            }}
          >
            <StyledP variant="body">
              {format(new Date(el.completion_time), dateFormat)}
            </StyledP>
          </div>
        ),
        timeRealisation: (
          <StyledP variant="body">
            {el.duration_iso8601 &&
              `${durationIso8601ToHour(
                el.duration_iso8601
              )}h ${durationIso8601ToMinute(el.duration_iso8601)}m`}
          </StyledP>
        ),
        daysCount: (() => {
          const daysToPlannedCompletionDate = differenceInDays(
            new Date(el.planned_completion_date),
            new Date()
          );

          return (
            <span
              style={{
                color:
                  daysToPlannedCompletionDate < 0
                    ? theme.palette.accent.red
                    : theme.palette.accent.green,
                fontSize: '13px'
              }}
            >
              {daysToPlannedCompletionDate}
            </span>
          );
        })(),
        options: rowButtons({ item: el })
      };
    });
  };

  const switchableItems = [
    'star',
    'practice',
    'name',
    'taskType',
    'responsible',
    'area',
    'status',
    'deadlineRealisation',
    'timeRealisation',
    'daysCount'
  ];

  const additionalHeader = (
    <Flex width="100%" flexDirection="column">
      <Flex
        width="100%"
        gap="10px"
        justifyContent="space-between"
        alignItems="center"
      >
        {showDetailedHeader && (
          <DetailedHeaderWrapper
            ml={-18}
            my={-2}
            py={14}
            px={2}
            minWidth="620px"
          >
            <TopBar
              customTitle={customTitle}
              setIsFormModalOpen={setIsFormModalOpen}
              paginationTotal={paginationTotal}
            />
          </DetailedHeaderWrapper>
        )}

        <FontAwesomeIcon icon={faSearch} />
        <TextField
          placeholder={t('employeesAndUsersView.form.search') ?? undefined}
          hideError
          onChange={(e) => {
            handleSearchOnChange(e);
          }}
        />
        <ColumnsOptions
          hiddenItems={hiddenItems}
          setHiddenItems={setHiddenItems}
          switchableItems={switchableItems}
          labelPrefix="tasks.task.table."
        />
      </Flex>
      {checkedItemsIds?.length ? (
        <ChosenItemsHeader>
          <Flex alignItems="center">
            <P variant="body" color="secondary">
              {checkedItemsIds?.length} - {t('tasks.chosenLines')}
            </P>
            <Button transparent variant="secondary">
              {t('common.uncheck')}
            </Button>
          </Flex>
          <Flex gap="4px">
            {checkedItemsIds?.length === 1 && (
              <Button
                variant="red"
                onClick={() => {
                  setIsCancelTaskModalOpen(
                    (prevIsCancelTaskModalOpen) => !prevIsCancelTaskModalOpen
                  );
                }}
              >
                {t('common.cancel')}
              </Button>
            )}
            <Button
              onClick={() => {
                setIsArchiveTaskModalOpen(
                  (prevIsArchiveTaskModalOpen) => !prevIsArchiveTaskModalOpen
                );
              }}
            >
              {t('tasks.toArchive')}
            </Button>
            <Button>{t('tasks.exportToCalendar')}</Button>
            <Button
              onClick={() => {
                setIsRemindLatecomersModalOpen(
                  (prevIsRemindLatecomersModalOpen) =>
                    !prevIsRemindLatecomersModalOpen
                );
              }}
            >
              {t('remindLatecomers')}
            </Button>
            <Button
              onClick={() => {
                setIsSendNotificationsModalOpen(
                  (prevIsSendNotificationsModalOpen) =>
                    !prevIsSendNotificationsModalOpen
                );
              }}
            >
              {t('tasks.sendNotificationsToSelectedOnes')}
            </Button>
          </Flex>
        </ChosenItemsHeader>
      ) : (
        <></>
      )}
    </Flex>
  );

  return (
    <>
      <Wrapper>
        {showTopBar && (
          <TopBar
            customTitle={customTitle}
            setIsFormModalOpen={setIsFormModalOpen}
            paginationTotal={paginationTotal}
          />
        )}

        <Box my={2}>
          <Table
            header={header}
            data={parseData(tasks)}
            pagination={{
              page: page,
              setPage: setPage,
              perPage: perPage,
              total: paginationTotal,
              setPerPage: setPerPage
            }}
            additionalHeaderElement={showAdditionalHeader && additionalHeader}
            hiddenItems={hiddenItems}
            fontSize="big"
            containerHeight={tableContainerHeight}
          />
        </Box>
      </Wrapper>
      <TaskFormModal
        data={selectedItemData}
        setSelectedItemData={setSelectedItemData}
        handleSubmitTaskForm={handleSubmitTaskForm}
        isOpen={IsFormModalOpen}
        onCancelClick={() => {
          setIsFormModalOpen(false);
          setSelectedItemData(undefined);
        }}
        handleDeleteTask={handleDeleteTask}
        handleLoadTasks={handleLoadTasks}
      />
      <CancelTaskModal
        isOpen={IsCancelTaskModalOpen}
        onCancelClick={() => {
          setIsCancelTaskModalOpen(false);
        }}
      />
      <Modal
        gridTemplateColumns="45%"
        isOpen={IsArchiveTaskModalOpen}
        onCancelClick={() => {
          setIsArchiveTaskModalOpen(false);
        }}
        headerTitle={t('tasks.archiveTaskConfirmation?')}
        mainButton={{
          action: () => {},
          variant: 'eucalyptus',
          label: t('common.yes')
        }}
        isSecondButtonVisible
      />
      <RemindLatecomersModal
        isOpen={IsRemindLatecomersModalOpen}
        onCancelClick={() => {
          setIsRemindLatecomersModalOpen(false);
        }}
      />
      <SendNotificationsModal
        isOpen={IsSendNotificationsModalOpen}
        onCancelClick={() => {
          setIsSendNotificationsModalOpen(false);
        }}
      />
      <StarModal
        selectedItemData={selectedItemData}
        isOpen={isStarModalOpen}
        onSuccess={async () => {
          await handleLoadTasks();
          setIsStarModalOpen(false);
        }}
        onCancelClick={() => {
          setIsStarModalOpen(false);
        }}
      />
    </>
  );
};
