import { Avatar, Box, Flex, Table } from 'components';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseye,
  faSquarePen,
  faPeopleGroup,
  faLockOpen,
  faPeopleRoof,
  faPeopleLine,
  faFileLines
} from '@fortawesome/free-solid-svg-icons';
import { faFileLines as faRegluarFileLines } from '@fortawesome/free-regular-svg-icons';

import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ColumnsOptions } from 'components/Table/components/ColumnsOptions';
import { theme } from 'styles';
import { getEvaluation } from 'api/evaluations';
import { IEvaluation } from 'types/evaluations';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { toast } from 'react-toastify';
import { EditEvaluationModal } from '../EvaluationActionsModal/EditEvaluationModal';
import { useNavigate } from 'react-router-dom';
import { parseItemsToMethodologyTree } from 'utilities/practices';
import { Loader } from 'components/Loader/Loader';
import { OptionButtons } from './components/OptionButtons';
import { format } from 'date-fns';
import { dateFormat } from 'constants/dateFormats/dateFormat';

const typeColorsDict = {
  normal: theme.palette.neutral.grey,
  target: theme.palette.accent.blue,
  baseline: theme.palette.accent.red,
  current: theme.palette.accent.green,
  derivative: theme.palette.accent.brown
};

interface Props {
  showAllButton?: boolean;
}

export const PracticeBasedAssessments: React.FC<Props> = ({
  showAllButton
}) => {
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const [loadingEvaluationIds, setLoadingEvaluationIds] = useState<number[]>(
    []
  );

  const {
    filters,
    evaluations,
    setEvaluations,
    selectedEvaluations,
    setSelectedEvaluations,
    setSelectedEvaluation,
    handleLoadEvaluations,
    evaluationToEditId,
    setEvaluationToEditId,
    setSelectedBlock
  } = useEvaluationContext();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSelectEvaulation = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, evaluationId: number) => {
      try {
        setLoadingEvaluationIds((prevLoadingEvaluationIds) => [
          ...prevLoadingEvaluationIds,
          evaluationId
        ]);
        if (e.target.checked) {
          const { data: evaluation } = await getEvaluation(evaluationId);

          if (evaluation) {
            setSelectedEvaluations((prevSelectedEvaluations) => [
              ...prevSelectedEvaluations,
              evaluation
            ]);
            setSelectedEvaluation(evaluation);
          }
        } else {
          setSelectedEvaluations((prevSelectedEvaluations) => [
            ...prevSelectedEvaluations.filter(
              (selectedEvaluation) => selectedEvaluation.id !== evaluationId
            )
          ]);
        }
        setLoadingEvaluationIds((prevLoadingEvaluationIds) =>
          prevLoadingEvaluationIds.filter(
            (loadingEvaluationId) => loadingEvaluationId !== evaluationId
          )
        );
      } catch (e) {
        toast.error('anErrorOccurred');
      }
    },
    [setSelectedEvaluation, setSelectedEvaluations]
  );

  useEffect(() => {
    handleLoadEvaluations();
  }, [handleLoadEvaluations]);

  const switchableItems = [
    'type',
    'number',
    'evaluationDate',
    'deadLine',
    'lastUpdate',
    'evaluator',
    'result',
    'status',
    'group',
    'name',
    'version'
  ];

  const header: (string | JSX.Element | null)[] = [
    '',
    t('evaluationsView.tableHeaders.type'),
    t('evaluationsView.tableHeaders.number'),
    t('evaluationsView.tableHeaders.evaluationDate'),
    t('evaluationsView.tableHeaders.deadLine'),
    t('evaluationsView.tableHeaders.lastUpdate'),
    t('evaluationsView.tableHeaders.evaluationLeader'),
    <FontAwesomeIcon key="bullseye" icon={faBullseye} />,
    <FontAwesomeIcon key="squarePen" icon={faSquarePen} />,
    <FontAwesomeIcon key="peopleGroup" icon={faPeopleGroup} />,
    t('evaluationsView.tableHeaders.name'),
    t('evaluationsView.tableHeaders.version'),
    <Flex key="options" ml="auto">
      <ColumnsOptions
        switchableItems={switchableItems}
        hiddenItems={hiddenItems}
        setHiddenItems={setHiddenItems}
        labelPrefix="evaluationsView.tableHeaders."
      />
    </Flex>
  ];

  const parseData = (data: IEvaluation[]) => {
    const methodologies = parseItemsToMethodologyTree(data);

    // preparing date to display in table
    const tableData = methodologies.map((methodology) => ({
      colType: 'internallyAccordable',
      primaryAccordion: <Flex>{methodology.name}</Flex>,
      children: methodology.practices.map((practice) => ({
        secondaryAccordion: {
          value: practice.name,
          bgColor: theme.palette.accent.eucalyptus
        },
        children: practice.items.map((evaluation) => ({
          checkbox: loadingEvaluationIds.includes(evaluation.id) ? (
            <Loader />
          ) : (
            <Checkbox
              unCheckedIconDisplay
              variant="secondary"
              checked={
                !!selectedEvaluations.find(({ id }) => evaluation.id === id)
              }
              onChange={(e) => {
                handleSelectEvaulation(e, evaluation.id);
              }}
            />
          ),
          type: (
            <FontAwesomeIcon
              icon={
                evaluation.evaluation_type === 'normal'
                  ? faRegluarFileLines
                  : faFileLines
              }
              size="2xl"
              color={typeColorsDict[evaluation.evaluation_type]}
            />
          ),
          number: evaluation.number,
          evaluationDate: evaluation.date,
          deadLine: evaluation.realization_date,
          lastUpdate:
            evaluation.updated_at &&
            format(new Date(evaluation.updated_at), dateFormat),
          evaluator: (
            <>
              <Flex justifyContent="flex-start" alignItems="center">
                <Avatar size="extra-small" />
                <Box ml={2}>{evaluation.leader?.username}</Box>
              </Flex>
            </>
          ),
          result: evaluation.avg_score,
          status: <FontAwesomeIcon icon={faLockOpen} />,
          group:
            evaluation.group === 'operators' ? (
              <FontAwesomeIcon
                icon={faPeopleLine}
                color={theme.palette.accent.red}
              />
            ) : evaluation.group === 'management' ? (
              <FontAwesomeIcon
                icon={faPeopleRoof}
                color={theme.palette.accent.blue}
              />
            ) : (
              ''
            ),
          name: evaluation.name,
          version: evaluation.version,
          options: <OptionButtons evaluation={evaluation} />
        }))
      }))
    }));

    return tableData;
  };

  const filterSelectedEvaluationByNumber = useCallback(
    (number: string) => {
      return selectedEvaluations?.find(
        (evaluation) => evaluation.number === number
      );
    },
    [selectedEvaluations]
  );

  return (
    <Flex flexDirection="column">
      {showAllButton && (
        <Flex>
          <Button
            onClick={() => {
              navigate('/app/evaluations');
            }}
          >
            {t('common.showAll')}
          </Button>
        </Flex>
      )}
      <Table
        header={header}
        data={parseData(evaluations)}
        leftFixedKeys={[0, 1]}
        hiddenItems={hiddenItems}
        onClickRow={(evaluation) => {
          setSelectedEvaluation(
            filterSelectedEvaluationByNumber(evaluation.number)
          );
        }}
      />
      <EditEvaluationModal
        isOpen={!!evaluationToEditId}
        onCancelClick={() => setEvaluationToEditId(undefined)}
      />
    </Flex>
  );
};
