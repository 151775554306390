import { Flex } from 'components';
import { TableCell } from './Row.styled';
import { ActiveDot } from 'components/ActiveDot/ActiveDot';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { Button, SwitchButton } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction } from 'react';
import { ITaskType } from 'types/taskType';
import { useAdminTaskTypeContext } from 'contexts/AdminTaskTypeContext';

interface Props {
  taskType: ITaskType;
  listLength: number;
  isSubRow?: boolean;
  setEditingTaskTypeId: Dispatch<SetStateAction<number | undefined>>;
  setParentTaskTypeId: Dispatch<SetStateAction<number | undefined>>;
  handleChangeTaskTypeOrder: (
    taskType: ITaskType,
    type: 'increase' | 'decrease'
  ) => void;
}

export const Row: React.FC<Props> = ({
  taskType,
  listLength,
  isSubRow,
  setEditingTaskTypeId,
  setParentTaskTypeId,
  handleChangeTaskTypeOrder
}) => {
  const { userFunctionsList } = useAdminTaskTypeContext();

  return (
    <Flex key={`taskTypesList-${taskType.id}`}>
      <Flex width="8%" justifyContent="end">
        <TableCell width={isSubRow ? '50%' : '100%'} justifyContent="center">
          <ActiveDot active={taskType.active} />
        </TableCell>
      </Flex>
      <TableCell width="28%">{taskType.name}</TableCell>
      <TableCell width="12%">
        <RealizationProgress
          activeAll
          realizationProgress={taskType.realization_progress}
        />
      </TableCell>
      <TableCell width="12%" overflow="hidden">
        <SwitchButton disabled value={taskType.requires_approval} />
      </TableCell>
      <TableCell width="12%">
        {taskType.functions_approving_steps
          ?.flatMap(({ realization_progress_approval_steps }) =>
            realization_progress_approval_steps.flatMap(
              ({ steps_to_approve }) => steps_to_approve
            )
          )
          .join(', ')}
      </TableCell>
      <TableCell width="17%">
        {taskType.functions_approving_steps
          ?.flatMap(
            ({ function_id }) =>
              userFunctionsList.find(
                (userFunction) =>
                  userFunction.value?.toString() === function_id?.toString()
              )?.label
          )
          .join(', ')}
      </TableCell>
      <TableCell width={isSubRow ? '8%' : '11%'} gap="5px">
        <Button
          variant="eucalyptus"
          icon={<FontAwesomeIcon icon={faEdit} size="xl" />}
          onClick={() => {
            setEditingTaskTypeId(taskType.id);
          }}
        />
        {!taskType.parent_id && (
          <Button
            variant="green"
            icon={<FontAwesomeIcon icon={faPlus} size="xl" />}
            onClick={() => {
              setParentTaskTypeId(taskType.id);
            }}
          />
        )}
        <Flex flexDirection="column">
          <Button
            bordered
            transparent
            icon={<FontAwesomeIcon icon={faChevronUp} size="xl" />}
            minHeight="17px"
            disabled={taskType.order <= 1}
            onClick={() => {
              handleChangeTaskTypeOrder(taskType, 'decrease');
            }}
          />
          <Button
            bordered
            transparent
            icon={<FontAwesomeIcon icon={faChevronDown} size="xl" />}
            minHeight="17px"
            disabled={taskType.order >= listLength}
            onClick={() => {
              handleChangeTaskTypeOrder(taskType, 'increase');
            }}
          />
        </Flex>
      </TableCell>
    </Flex>
  );
};
