import { getAreas } from 'api/areas';
import { getRealizationProgresses } from 'api/realizationProgresses';
import { Flex, Line } from 'components';
import { FieldLabel, Radio, SwitchButton, TextField } from 'components/_form';
import { ColorPicker } from 'components/_form/ColorPicker/ColorPicker';
import { ErrorText } from 'components/_form/ErrorText';
import {
  NestedSelect,
  IOption as OptionForNestedSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { flattenOptions } from 'components/_form/NestedSelect/utilities';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IRealizationProgress } from 'types/realizationProgresses';
import { ITaskTypePayload } from 'types/taskType';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';
import { ApprovingStepsTable } from './components/ApprovingStepsTable/ApprovingStepsTable';
import { useTranslation } from 'react-i18next';

interface Props {}

export const TaskTypeForm: React.FC<Props> = () => {
  const [realizationProgresses, setRealizationProgresses] = useState<
    IRealizationProgress[]
  >([]);
  const [areas, setAreas] = useState<OptionForNestedSelect[]>([]);

  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<ITaskTypePayload['task_type']>();

  const handleGetRealizationProgresses = async () => {
    const realizationProgressesRes = await getRealizationProgresses();

    if ('error' in realizationProgressesRes) {
      return;
    }

    if (realizationProgressesRes.data) {
      setRealizationProgresses(realizationProgressesRes.data);
    }
  };

  const handleGetAreas = async () => {
    const { data: areasData } = await getAreas();

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areasData)
      )
    );
  };

  useEffect(() => {
    handleGetRealizationProgresses();
    handleGetAreas();
  }, []);

  return (
    <Flex flexDirection="column" gap="10px">
      <Flex alignItems="center" flexDirection={['column', 'row']}>
        <Flex width={['100%', '20%']}>
          <FieldLabel>
            {t('administrationTasksView.clickToChooseColor')}
          </FieldLabel>
        </Flex>
        <Flex width="80%">
          <Controller
            control={control}
            name="color_code"
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                controlledDisplayColorPicker={value}
                onChange={onChange}
              />
            )}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="end" width="100%">
        <Flex width={['100%', '80%']} gap="10px">
          <TextField
            {...register('color_icon')}
            width="80px"
            label={t('administrationTasksView.code')}
            error={t(errors.color_icon?.message || '')}
          />
          <TextField
            {...register('name')}
            label={t('administrationTasksView.name')}
            error={t(errors.name?.message || '')}
          />
        </Flex>
      </Flex>

      <Line variant="secondary" />

      <Flex flexDirection={['column', 'row']}>
        <Flex width="20%" flexDirection="column" gap="4px">
          <FieldLabel>{t('administrationTasksView.active?')}</FieldLabel>
          <Controller
            control={control}
            name="active"
            render={({ field: { onChange, value } }) => (
              <SwitchButton
                value={!!value}
                onChange={(checked) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </Flex>
        <Flex width="80%">
          <Controller
            control={control}
            name="area_ids"
            render={({ field: { onChange, value } }) => (
              <NestedSelect
                width="100%"
                label={t('administrationTasksView.usedInAreas')}
                selectedOptions={flattenOptions(areas).filter(
                  (option) => option?.value && value?.includes(option?.value)
                )}
                setSelectedOptions={(newOptions) => {
                  onChange(
                    flattenOptions(newOptions).map(
                      (newOption) => newOption.value
                    )
                  );
                }}
                options={areas}
              />
            )}
          />
        </Flex>
      </Flex>

      <Line variant="secondary" />

      <Flex alignItems="center" flexDirection={['column', 'row']}>
        <Flex width={['100%', '20%']}>
          <FieldLabel>
            {t('administrationTasksView.followingStatusBy')}
          </FieldLabel>
        </Flex>
        <Flex width={['100%', '80%']} flexDirection="column">
          <Controller
            control={control}
            name="realization_progress_id"
            render={({ field: { value, onChange } }) => (
              <Flex gap="100px" flexDirection={['column', 'row']}>
                {realizationProgresses.map((realizationProgress) => (
                  <Flex
                    key={`TasksSettingsForm-realizationProgresses-${realizationProgress.id}`}
                  >
                    <Radio
                      checked={value === realizationProgress.id}
                      onClick={() => {
                        onChange(realizationProgress.id);
                      }}
                    />
                    <Flex width={['150px', '38px']}>
                      <RealizationProgress
                        activeAll
                        realizationProgress={realizationProgress}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}
          />
          <ErrorText>
            {t(errors.realization_progress_id?.message || '')}
          </ErrorText>
        </Flex>
      </Flex>

      <Line variant="secondary" />

      <Flex gap="8px" flexDirection={['column', 'row']}>
        <Flex flexDirection="column" gap="4px">
          <FieldLabel>
            {t('administrationTasksView.requireApproval')}
          </FieldLabel>
          <Controller
            control={control}
            name="requires_approval"
            render={({ field: { onChange, value } }) => (
              <SwitchButton value={value} onChange={onChange} />
            )}
          />
        </Flex>

        <ApprovingStepsTable realizationProgresses={realizationProgresses} />
      </Flex>
    </Flex>
  );
};
