import { Flex } from 'components';
import { RatingScaleButton } from 'components/RatingScaleButtons/RatingScaleButton';
import { RatingScaleButtons } from 'components/RatingScaleButtons/RatingScaleButtons';
import ClickOutside from 'components/click-outside';
import { useState } from 'react';
import styled from 'styled-components';
import { device } from 'styles/theme';
import { IRatingScale, IRatingScaleButton } from 'types/ratingScales';
import {
  DescriptionContentWrapper,
  StyledMDEditor
} from '../AuditCriteriaTable/AuditCriteriaTable.styled';
import { IDetailedAuditCriterion } from 'types/audits';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapLocationDot,
  faRankingStar
} from '@fortawesome/free-solid-svg-icons';

const CellWrapper = styled(Flex)`
  cursor: pointer;
  position: relative;
`;

const ButtonsWrapper = styled(Flex)`
  position: absolute;
  top: 70px;
  z-index: 10;

  @media ${device.tablet} {
    right: 0px;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 101;
`;

interface Props {
  onChooseScore: (buttonId: number, criterionId: number | string) => void;
  criterion: IDetailedAuditCriterion | Partial<IDetailedAuditCriterion>;
  selectedButton?: IRatingScaleButton;
  cellHeight: number;
  ratingScale?: IRatingScale;
  onClick?: () => void;
  onClickOutside?: () => void;
}

export const AuditCriteriaNumberCell = ({
  onChooseScore,
  criterion,
  selectedButton,
  cellHeight,
  ratingScale,
  onClick,
  onClickOutside
}: Props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <ClickOutside
      active={isActive}
      onClick={() => {
        setIsActive(false);
        onClickOutside?.();
      }}
    >
      <CellWrapper
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        height={`${isActive ? cellHeight - 20 : cellHeight - 5}px`}
        onClick={() => {
          setIsActive((prevIsActive) => !prevIsActive);
          onClick?.();
        }}
      >
        <Flex justifyContent="center" alignItems="center" height="100%">
          {selectedButton && (
            <RatingScaleButton
              button={selectedButton}
              showImages={ratingScale?.button_type === 'picture'}
              useValues={ratingScale?.use_colored_values}
              borderRadius
            />
          )}
        </Flex>

        {isActive && (
          <ButtonsWrapper
            ml={`${-(ratingScale?.buttons?.length || 1) * 10}px`}
            flexDirection="column"
            alignItems="end"
          >
            <RatingScaleButtons
              buttons={ratingScale?.buttons || []}
              showImages={ratingScale?.button_type === 'picture'}
              bordered={ratingScale?.border}
              borderRadius
              onButtonClick={(button) => {
                criterion.id && onChooseScore(Number(button.id), criterion.id);
              }}
            />

            <Flex flexDirection="column" display={['flex', 'none']}>
              <DescriptionContentWrapper
                data-color-mode="light"
                height="150px"
                width="80vw"
              >
                <StyledFontAwesomeIcon icon={faRankingStar} size="2xs" />

                <StyledMDEditor
                  height="100%"
                  enableScroll={false}
                  visibleDragbar={false}
                  hideToolbar={true}
                  preview={'preview'}
                  value={criterion?.scale_description || ''}
                />
              </DescriptionContentWrapper>

              <DescriptionContentWrapper
                data-color-mode="light"
                height="150px"
                width="80vw"
              >
                <StyledFontAwesomeIcon icon={faMapLocationDot} size="2xs" />

                <StyledMDEditor
                  height="100%"
                  enableScroll={false}
                  visibleDragbar={false}
                  hideToolbar={true}
                  preview={'preview'}
                  value={criterion?.tips || ''}
                />
              </DescriptionContentWrapper>
            </Flex>
          </ButtonsWrapper>
        )}
      </CellWrapper>
    </ClickOutside>
  );
};
