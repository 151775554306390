import {
  faCalendar,
  faEdit,
  faFolderOpen,
  faPaperPlane
} from '@fortawesome/free-regular-svg-icons';
import {
  faClipboard,
  faClockRotateLeft,
  faPeopleGroup,
  faPersonWalking,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, DatePicker, Flex, Line } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H4, P } from 'components/Typography/Typography';
import {
  Button,
  FieldLabel,
  Select,
  SwitchButton,
  TextareaField,
  TextField
} from 'components/_form';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ITaskForm } from 'types/forms/task';
import { ITask } from 'types/task';
import { CountTag } from './components/CountTag';
import { DeleteTaskModal } from './components/DeleteTaskModal';
import { DescriptionModal } from '../../../../components/_form/DescriptionModal/DescriptionModal';
import { FilesModal } from '../../../../components/FilesModal/FilesModal';
import { NotesModal } from '../../../../components/NotesModal/NotesModal';
import { StarModal } from './components/StarModal';
import { TaskParticipantsModal } from './components/TaskParticipantsModal';
import { getTask, getTaskPriorityDegrees } from 'api/tasks';
import { IOption } from 'components/_form/Select/Select';
import { getFile } from 'utilities/files';
import { yupResolver } from '@hookform/resolvers/yup';
import { taskSchema } from 'validation/taskSchemas';
import { getUsers } from 'api/user';
import { ITeam } from 'types/team';
import { getTeams } from 'api/teams';
import { getPractices } from 'api/methodologies';
import {
  makeMethodologyPracticeTree,
  parseMethodologyPracticeTreeForSelect
} from 'utilities/practices';
import {
  NestedSelect,
  IOption as OptionForSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { getAreas } from 'api/areas';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';
import { flattenOptions } from 'components/_form/NestedSelect/utilities';
import { format } from 'date-fns';
import { hourChecker, minuteChecker } from 'utilities/timePicker';
import { LabelPosition } from 'components/_form/TextField/TextField';
import { fullName } from 'utilities/fullName';
import { iso8601HourAndHalf } from 'constants/iso8601';
import { IRealizationStatusAttribute } from 'types/realizationStatuses';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { IRealizationProgress } from 'types/realizationProgresses';
import { getRealizationProgresses } from 'api/realizationProgresses';
import { IUser } from 'types/user';
import { getTaskTypes } from 'api/taskTypes';
import { useAccount } from 'hooks/useAccount';
import { Loader } from 'components/Loader/Loader';
import { TaskStar } from 'components/TaskStar/TaskStar';
import { durationIso8601ToHour } from 'utilities/durationIso8601ToHour';
import { durationIso8601ToMinute } from 'utilities/durationIso8601ToMinute';

export interface TaskForm {
  name: string;
  description: string;
  priority?: { value: string; label: JSX.Element };
  taskType?: { value: string; label: string };
  images: File[];
  documents: File[];
  author_id: string;
  person_in_charge_id: string;
  team_leader_id: string;
  team_manager_id: string;
  team_ids: string[];
  task_participations_attributes?: {
    value: string;
    label: string | JSX.Element;
    function_id?: string;
  }[];
  practice_id: string;
  area_ids: string[];
  only_realization: boolean;
  planned_start_date: string;
  planned_completion_date: string;
  start_time: string;
  completion_time: string;
  planned_duration: string;
  duration: string;
  realization_statuses_attributes: IRealizationStatusAttribute[];
}

interface InitialValues {
  name: string;
  description: string;
}

interface Props extends IGenericModal {
  data?: ITask;
  setSelectedItemData?: React.Dispatch<React.SetStateAction<ITask | undefined>>;
  handleSubmitTaskForm: (data: ITaskForm['task']) => Promise<void>;
  handleDeleteTask?: () => void;
  handleLoadTasks?: () => Promise<void>;
  initialValues?: InitialValues;
}

export interface ITeamOption extends IOption {
  members: ITeam['members'];
}

interface ITaskTypeOption extends IOption {
  realization_progress: IRealizationProgress;
}

export const TaskFormModal = ({
  isOpen,
  onCancelClick,
  data,
  setSelectedItemData,
  handleSubmitTaskForm,
  handleDeleteTask,
  handleLoadTasks,
  initialValues
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [isStarModalOpen, setIsStarModalOpen] = useState(false);
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [priorities, setPriorities] = useState<IOption[]>([]);
  const [taskTypes, setTaskTypes] = useState<ITaskTypeOption[]>([]);
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [teamsList, setTeamsList] = useState<ITeamOption[]>([]);
  const [methodologyTreeForSelect, setMethodologyTreeForSelect] = useState<
    OptionForSelect[]
  >([]);
  const [areas, setAreas] = useState<OptionForSelect[]>([]);
  const [realizationProgress, setRealizationProgress] =
    useState<IRealizationProgress>();
  const [realizationProgresses, setRealizationProgresses] = useState<
    IRealizationProgress[]
  >([]);

  const usersOptions = usersList.map((user) => ({
    label: fullName(user.profile),
    value: String(user.id)
  }));

  const { t } = useTranslation();

  const { loggedUserData } = useAccount();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm<TaskForm>({
    mode: 'onChange',
    resolver: yupResolver(taskSchema)
  });

  const clearFields = useCallback(() => {
    reset({
      name: '',
      description: '',
      priority: undefined,
      taskType: undefined,
      images: [],
      documents: [],
      team_ids: [],
      author_id: '',
      person_in_charge_id: '',
      team_leader_id: '',
      team_manager_id: '',
      task_participations_attributes: [],
      area_ids: [],
      only_realization: false,
      planned_start_date: '',
      planned_completion_date: '',
      start_time: '',
      completion_time: '',
      planned_duration: iso8601HourAndHalf,
      duration: iso8601HourAndHalf
    });
  }, [reset]);

  const loadData = useCallback(async () => {
    if (data) {
      const {
        id,
        author,
        person_in_charge,
        team_leader,
        team_manager,
        name,
        description,
        priority_degree,
        task_type,
        image_urls,
        document_urls,
        task_participations,
        teams,
        practice,
        areas,
        planned_duration_iso8601,
        duration_iso8601,
        realization_statuses,
        ...restData
      } = data;

      reset({
        ...restData,
        author_id: author?.id?.toString(),
        person_in_charge_id: person_in_charge?.id?.toString(),
        team_leader_id: team_leader?.id?.toString(),
        team_manager_id: team_manager?.id?.toString(),
        priority: priority_degree
          ? {
              value: JSON.stringify(priority_degree.id),
              label: (
                <Flex gap="10px" alignItems="center">
                  <img
                    width="20px"
                    height="20px"
                    src={
                      process.env.REACT_APP_API_URL + priority_degree.image_url
                    }
                  />
                  {priority_degree.name}
                </Flex>
              )
            }
          : {},
        taskType: task_type
          ? { value: JSON.stringify(task_type.id), label: task_type.name }
          : {},
        images: image_urls
          ? (await Promise.all(
              image_urls.map((image_url) => getFile(image_url))
            ).then((values) => {
              return values;
            })) || []
          : [],
        documents: document_urls
          ? (await Promise.all(
              document_urls.map((document_url) => getFile(document_url))
            ).then((values) => {
              return values;
            })) || []
          : [],
        task_participations_attributes: task_participations.map(
          (taskParticipation) => ({
            value: taskParticipation.participant.id,
            label: `${taskParticipation.participant.first_name} ${taskParticipation.participant.last_name}`,
            function_id: taskParticipation.function?.id
          })
        ),
        team_ids: teams?.map((team) => team.id.toString()),
        practice_id: practice?.id?.toString(),
        area_ids: areas?.map((area) => area.id.toString()),
        planned_duration: planned_duration_iso8601,
        duration: duration_iso8601,
        name: initialValues?.name || name || '',
        description: initialValues?.description || description || '',
        realization_statuses_attributes: realization_statuses
      });
    } else if ((initialValues?.description, initialValues?.name)) {
      reset({
        author_id: loggedUserData?.id?.toString(),
        name: initialValues?.name || '',
        description: initialValues?.description || ''
      });
    } else {
      clearFields();
    }
  }, [
    clearFields,
    data,
    initialValues?.description,
    initialValues?.name,
    reset,
    loggedUserData?.id
  ]);

  const loadOptions = useCallback(async () => {
    setLoading(true);
    const { data: taskTypes } = await getTaskTypes();

    const taskTypesOptions = taskTypes.map(
      ({ name, id, realization_progress }) => ({
        value: String(id),
        label: name,
        realization_progress
      })
    );

    setTaskTypes(taskTypesOptions);

    const { data: priorities } = await getTaskPriorityDegrees();

    const prioritiesOptions = priorities.map(({ name, id, image_url }) => ({
      value: String(id),
      label: (
        <Flex gap="10px" alignItems="center">
          <img
            width="20px"
            height="20px"
            src={process.env.REACT_APP_API_URL + image_url}
          />
          {name}
        </Flex>
      )
    }));

    setPriorities(prioritiesOptions);

    const { data } = await getUsers();

    setUsersList(data);

    const teamsReponse = await getTeams();

    setTeamsList(
      teamsReponse.data.map((team) => ({
        value: team.id?.toString(),
        label: team.name,
        members: team.members
      }))
    );

    const { data: areasData } = await getAreas();

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areasData)
      )
    );

    const { data: realizationProgresses } = await getRealizationProgresses();

    setRealizationProgresses(realizationProgresses);

    const foundRealizationProgress =
      realizationProgresses.find(
        ({ id }) =>
          id ===
          watch('realization_statuses_attributes')?.[0]?.realization_progress_id
      ) ||
      realizationProgresses.find((finRealizationProgresses) => {
        return finRealizationProgresses.default;
      });

    setRealizationProgress(foundRealizationProgress);
    setLoading(false);
  }, [JSON.stringify(watch('realization_statuses_attributes'))]);

  const handleGetPractices = useCallback(async () => {
    const { data } = await getPractices();

    const methodologyObject = makeMethodologyPracticeTree(data);

    const treeForSelect =
      parseMethodologyPracticeTreeForSelect(methodologyObject);
    setMethodologyTreeForSelect(treeForSelect);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    handleGetPractices();
  }, [handleGetPractices]);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={() => {
        onCancelClick();
      }}
      headerTitle={data ? t('tasks.editTask') : t('tasks.addTask')}
      mainButton={{
        action: handleSubmit((data) => {
          handleSubmitTaskForm(data);
        }),
        variant: 'eucalyptus',
        label: t(data ? 'buttons.save' : 'tasks.addTask')
      }}
      isSecondButtonVisible
      gridTemplateColumns="87%"
    >
      {loading ? (
        <Loader />
      ) : (
        <form>
          <Flex flexDirection="column">
            <Flex gap="10px" flexDirection={['column', 'row']}>
              <Flex width={['100%', '50%']} flexDirection="column" gap="6px">
                <Flex flexDirection="column">
                  <Controller
                    control={control}
                    name="practice_id"
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <NestedSelect
                          label={'practice'}
                          selectedOptions={methodologyTreeForSelect
                            .map((item) => item.options || [])
                            .flat()
                            .filter((option) => option?.value === value)}
                          setSelectedOptions={(newOption) => {
                            onChange(newOption[0]?.value);
                          }}
                          options={methodologyTreeForSelect}
                          isOneOptionSelect={true}
                        />
                      </div>
                    )}
                  />
                </Flex>
                <TextareaField
                  {...register('name')}
                  defaultValue={initialValues?.name || ''}
                  label={t('tasks.taskName') || ''}
                  error={t(errors?.name?.message || '')}
                  disabled={false}
                />
                <Flex>
                  <TextareaField
                    {...register('description')}
                    label={t('administration.description') || ''}
                    rows={5}
                    error={t(errors?.description?.message || '')}
                    disabled
                    ExternalItem={
                      <Button
                        variant="secondary"
                        icon={
                          <FontAwesomeIcon
                            color="grey"
                            size="2x"
                            icon={faEdit}
                          />
                        }
                        onClick={() => {
                          setIsDescriptionModalOpen(
                            (prevIsDescriptionModalOpen) =>
                              !prevIsDescriptionModalOpen
                          );
                        }}
                      />
                    }
                  />
                </Flex>
                <Flex>
                  <Flex mr={2} width={['100%', '50%']}>
                    <Controller
                      control={control}
                      name="priority"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          height="50px"
                          label="tasks.Priority"
                          selectedOptions={value}
                          onChange={(val) => {
                            onChange(val);
                          }}
                          options={priorities}
                          withoutMargin
                        />
                      )}
                    />
                  </Flex>

                  <Controller
                    control={control}
                    name="taskType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        height="50px"
                        label="tasks.TaskType"
                        options={taskTypes}
                        selectedOptions={value}
                        onChange={(val: ITaskTypeOption) => {
                          onChange(val);
                          setRealizationProgress(
                            realizationProgresses.find(
                              ({ id }) => id === val.realization_progress?.id
                            )
                          );
                        }}
                        withoutMargin
                      />
                    )}
                  />
                </Flex>
              </Flex>

              <Flex width={['100%', '50%']} flexDirection="column" gap="6px">
                <Controller
                  control={control}
                  name="area_ids"
                  render={({ field: { onChange, value } }) => (
                    <NestedSelect
                      label={t('Areas') as string}
                      selectedOptions={flattenOptions(areas).filter((option) =>
                        value?.includes(option?.value || '')
                      )}
                      setSelectedOptions={(newOptions) => {
                        onChange(
                          flattenOptions(newOptions).map(
                            (newOption) => newOption.value
                          )
                        );
                      }}
                      options={areas}
                    />
                  )}
                />

                <Flex>
                  <Flex mr={2} width="50%">
                    <Controller
                      control={control}
                      name="person_in_charge_id"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('tasks.responsible')}
                          selectedOptions={usersOptions.find(
                            (userOption) => userOption.value === value
                          )}
                          onChange={(val: IOption) => {
                            onChange(val.value);
                          }}
                          options={usersOptions}
                        />
                      )}
                    />
                  </Flex>
                  <Flex width="50%">
                    <Controller
                      control={control}
                      name="author_id"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          disabled
                          label={t('author')}
                          selectedOptions={usersOptions.find(
                            (userOption) => userOption.value === value
                          )}
                          onChange={(val: IOption) => {
                            onChange(val.value);
                          }}
                          options={usersOptions}
                        />
                      )}
                    />
                  </Flex>
                </Flex>

                <Flex alignItems="end">
                  <TextareaField
                    disabled
                    label={t('tasks.participants') || ''}
                    value={watch('task_participations_attributes')?.map(
                      (taskParticipationAttribute) =>
                        `${taskParticipationAttribute.label} \n`
                    )}
                  />
                  <Box ml={2}>
                    <Button
                      variant="eucalyptus"
                      onClick={() => {
                        setIsParticipantsModalOpen(
                          (prevIsParticipantsModalOpen) =>
                            !prevIsParticipantsModalOpen
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faPeopleGroup} />
                      <P variant="body">{t('buttons.manage')}</P>
                    </Button>
                  </Box>
                </Flex>

                <Flex mt={1}>
                  <Controller
                    control={control}
                    name="team_ids"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isMulti
                        label={t('Teams, changes, groups')}
                        selectedOptions={teamsList.filter((teamItem) =>
                          value?.includes(teamItem.value)
                        )}
                        onChange={(options: IOption[]) => {
                          onChange(options.map((option) => option.value));
                        }}
                        options={teamsList}
                      />
                    )}
                  />
                </Flex>
                <Flex justifyContent="space-between" flexWrap="wrap" gap="5px">
                  <Button
                    minWidth="100px"
                    minHeight="50px"
                    variant="eucalyptus"
                  >
                    <Flex justifyContent="space-around" alignItems="center">
                      <FontAwesomeIcon size="xl" icon={faPersonWalking} />
                      <P ml={2} variant="body">
                        {t('tasks.carryOut')}
                      </P>
                    </Flex>
                  </Button>

                  <Flex flexWrap="wrap">
                    {handleLoadTasks && (
                      <Flex mr={1}>
                        <Button
                          minWidth="50px"
                          minHeight="50px"
                          variant="secondary"
                          bordered
                          transparent
                          icon={<TaskStar task={data} size="2x" />}
                          onClick={() => {
                            setIsStarModalOpen(
                              (prevIsStarModalOpen) => !prevIsStarModalOpen
                            );
                          }}
                        />
                      </Flex>
                    )}
                    <Flex mr={1}>
                      <Button
                        minWidth="50px"
                        minHeight="50px"
                        variant={
                          watch('images')?.length + watch('documents')?.length
                            ? 'primary'
                            : 'secondary'
                        }
                        transparent
                        bordered
                        icon={
                          <>
                            <FontAwesomeIcon icon={faFolderOpen} size="2x" />
                            <CountTag
                              value={
                                (watch('images')?.length || 0) +
                                  (watch('documents')?.length || 0) || ''
                              }
                            />
                          </>
                        }
                        onClick={() => {
                          setIsFilesModalOpen(
                            (prevIsFilesModalOpen) => !prevIsFilesModalOpen
                          );
                        }}
                      />
                    </Flex>
                    <Flex mr={1}>
                      <Button
                        minWidth="50px"
                        minHeight="50px"
                        disabled={!data}
                        variant="blueWhite"
                        bordered
                        transparent
                        icon={<FontAwesomeIcon icon={faClipboard} size="2x" />}
                        onClick={() => {
                          setIsNotesModalOpen(
                            (prevIsNotesModalOpen) => !prevIsNotesModalOpen
                          );
                        }}
                      />
                    </Flex>
                    <Flex mr={1}>
                      <Button
                        minWidth="50px"
                        minHeight="50px"
                        variant="blueWhite"
                        bordered
                        transparent
                        icon={<FontAwesomeIcon icon={faPaperPlane} size="2x" />}
                      />
                    </Flex>
                    <Flex mr={1}>
                      <Button
                        minWidth="50px"
                        minHeight="50px"
                        variant="greyWhite"
                        transparent
                        bordered
                        icon={
                          <FontAwesomeIcon icon={faClockRotateLeft} size="2x" />
                        }
                      />
                    </Flex>
                    {handleDeleteTask && (
                      <Button
                        minWidth="50px"
                        minHeight="50px"
                        variant="redWhite"
                        bordered
                        transparent
                        icon={<FontAwesomeIcon icon={faTrash} size="2x" />}
                        onClick={() => {
                          setIsDeleteTaskModalOpen(
                            (prevIsNotesModalOpen) => !prevIsNotesModalOpen
                          );
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column" mt={2}>
              <H4 variant="h4" color="coloured">
                {t('tasks.planning')}
              </H4>
              <Line />

              <Flex gap="5px" flexWrap="wrap">
                <Flex width={['100%', '25%']} flexDirection="column">
                  <FieldLabel>{t('tasks.plannedStartDate') || ''}</FieldLabel>
                  <Controller
                    control={control}
                    name="planned_start_date"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        icon={
                          <FontAwesomeIcon color="grey" icon={faCalendar} />
                        }
                        value={value ? new Date(value) : null}
                        onChange={(newValue) => {
                          onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                        }}
                      />
                    )}
                  />
                </Flex>

                <Flex width={['100%', '25%']} flexDirection="column">
                  <FieldLabel>
                    {t('tasks.timeLimitForCompletion') || ''}
                  </FieldLabel>
                  <Controller
                    control={control}
                    name="planned_completion_date"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        icon={
                          <FontAwesomeIcon color="grey" icon={faCalendar} />
                        }
                        value={value ? new Date(value) : null}
                        onChange={(newValue) => {
                          onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                        }}
                      />
                    )}
                  />
                </Flex>
                <Flex flexDirection="column" width={['100%', '20%']}>
                  <FieldLabel>{t('tasks.plannedTime')}</FieldLabel>
                  <Flex mr={1} width="100%" alignItems="end">
                    <Flex mr={1} width="50%">
                      <Controller
                        control={control}
                        name="planned_duration"
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="tasks.hrs."
                            type="number"
                            value={Number(
                              value?.slice(
                                value.indexOf('T') + 1,
                                value.indexOf('H')
                              )
                            )}
                            labelPosition={LabelPosition.BOTTOM}
                            onChange={(newValue) => {
                              if (!hourChecker(newValue.target.value)) return;

                              onChange(
                                `${value.slice(0, value.indexOf('T') + 1)}${
                                  newValue.target.value
                                }${value.slice(value.indexOf('H'))}`
                              );
                            }}
                          />
                        )}
                      />
                    </Flex>
                    <Flex width="50%">
                      <Controller
                        control={control}
                        name="planned_duration"
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="tasks.min."
                            type="number"
                            value={Number(
                              value?.slice(
                                value.indexOf('H') + 1,
                                value.indexOf('M')
                              )
                            )}
                            labelPosition={LabelPosition.BOTTOM}
                            onChange={(newValue) => {
                              if (!minuteChecker(newValue.target.value)) return;

                              onChange(
                                `${value.slice(0, value.indexOf('H') + 1)}${
                                  newValue.target.value
                                }${value.slice(value.indexOf('M'))}`
                              );
                            }}
                          />
                        )}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex width={['100%', '30%']} mt={1} justifyContent="center">
                  <Controller
                    control={control}
                    name="only_realization"
                    render={({ field: { onChange, value } }) => (
                      <SwitchButton
                        label="tasks.realizationOnly (D)"
                        value={value}
                        onChange={() => {
                          if (realizationProgress?.kind === 'PDCA') {
                            if (
                              !watch('realization_statuses_attributes')?.find(
                                ({ kind }) => kind === 'P'
                              )
                            ) {
                              setValue('realization_statuses_attributes', [
                                ...(watch('realization_statuses_attributes') ||
                                  []),
                                {
                                  kind: 'P',
                                  confirmed_at: JSON.parse(
                                    JSON.stringify(new Date())
                                  ),
                                  realization_progress_id:
                                    realizationProgress.id,
                                  approver_id: Number(loggedUserData.id)
                                }
                              ]);
                            }
                          }

                          onChange();
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex flexDirection="column">
              <H4 variant="h4" color="green">
                {t('tasks.implementation')}
              </H4>
              <Line variant="tertiary" />

              <Flex
                gap="5px"
                alignItems="center"
                flexDirection={['column', 'row']}
              >
                <Flex
                  mt={['0px', '-19px']}
                  width={['100%', '25%']}
                  flexDirection="column"
                >
                  <FieldLabel>{t('tasks.plannedStartDate') || ''}</FieldLabel>
                  <Controller
                    control={control}
                    name="start_time"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        icon={
                          <FontAwesomeIcon color="grey" icon={faCalendar} />
                        }
                        value={value ? new Date(value) : null}
                        onChange={(newValue) => {
                          onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                        }}
                      />
                    )}
                  />
                </Flex>

                <Flex
                  mt={['0px', '-19px']}
                  width={['100%', '25%']}
                  flexDirection="column"
                >
                  <FieldLabel>
                    {t('tasks.timeLimitForCompletion') || ''}
                  </FieldLabel>
                  <Controller
                    control={control}
                    name="completion_time"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        icon={
                          <FontAwesomeIcon color="grey" icon={faCalendar} />
                        }
                        value={value ? new Date(value) : null}
                        onChange={(newValue) => {
                          onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                        }}
                      />
                    )}
                  />
                </Flex>
                <Flex flexDirection="column" width={['100%', '20%']}>
                  <FieldLabel>{t('tasks.plannedTime')}</FieldLabel>
                  <Flex mr={1} width="100%" alignItems="end">
                    <Flex mr={1} width="50%">
                      <Controller
                        control={control}
                        name="duration"
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="tasks.hrs."
                            type="number"
                            value={durationIso8601ToHour(value)}
                            labelPosition={LabelPosition.BOTTOM}
                            onChange={(newValue) => {
                              if (!minuteChecker(newValue.target.value)) return;

                              onChange(
                                `${value.slice(0, value.indexOf('T') + 1)}${
                                  newValue.target.value
                                }${value.slice(value.indexOf('H'))}`
                              );
                            }}
                          />
                        )}
                      />
                    </Flex>
                    <Flex width="50%">
                      <Controller
                        control={control}
                        name="duration"
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="tasks.min."
                            type="number"
                            value={durationIso8601ToMinute(value)}
                            labelPosition={LabelPosition.BOTTOM}
                            onChange={(newValue) => {
                              if (!minuteChecker(newValue.target.value)) return;

                              onChange(
                                `${value.slice(0, value.indexOf('H') + 1)}${
                                  newValue.target.value
                                }${value.slice(value.indexOf('M'))}`
                              );
                            }}
                          />
                        )}
                      />
                    </Flex>
                  </Flex>
                </Flex>

                <Flex width={['100%', '30%']} justifyContent="end">
                  <Flex>
                    {realizationProgress && (
                      <Controller
                        control={control}
                        name={'realization_statuses_attributes'}
                        render={({ field: { value, onChange } }) => (
                          <RealizationProgress
                            label={t('createTaskView.status')}
                            realizationProgress={realizationProgress}
                            realizationStatusAttributes={value || []}
                            onChange={onChange}
                            usersList={usersList}
                            showStatusInfo
                            onlyRealization={watch('only_realization')}
                          />
                        )}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <DescriptionModal
            value={watch('description')}
            setValue={(value) => {
              setValue('description', value || '');
            }}
            isOpen={isDescriptionModalOpen}
            onCancelClick={() => {
              setIsDescriptionModalOpen(false);
            }}
          />
          <TaskParticipantsModal
            isOpen={isParticipantsModalOpen}
            control={control}
            watch={watch}
            setValue={setValue}
            usersList={usersOptions}
            teamsList={teamsList}
            onCancelClick={() => {
              setIsParticipantsModalOpen(false);
            }}
            saveAndClose={() => {
              setIsParticipantsModalOpen(false);
            }}
          />
          {handleLoadTasks && (
            <StarModal
              selectedItemData={data}
              isOpen={isStarModalOpen}
              onSuccess={async () => {
                if (data?.id) {
                  const getTaskRes = await getTask(data.id);

                  if (getTaskRes.data) {
                    setSelectedItemData?.(getTaskRes.data);
                  }
                }

                await handleLoadTasks();
                setIsStarModalOpen(false);
              }}
              onCancelClick={() => {
                setIsStarModalOpen(false);
              }}
            />
          )}
          <FilesModal
            isOpen={isFilesModalOpen}
            onCancelClick={() => {
              setIsFilesModalOpen(false);
            }}
            // https://github.com/orgs/react-hook-form/discussions/7764#discussioncomment-2123788
            // @ts-ignore
            documents={watch('documents') || []}
            setDocuments={(documents: File[]) => {
              setValue('documents', documents);
            }}
            images={watch('images') || []}
            setImages={(images: File[]) => {
              setValue('images', images);
            }}
          />
          <NotesModal
            isOpen={isNotesModalOpen}
            onCancelClick={() => {
              setIsNotesModalOpen(false);
            }}
            noteableId={data?.id}
            noteableType="Task"
            notes={data?.notes}
            setNotes={(newNotes) =>
              setSelectedItemData?.(
                (prevSelectedItemData) =>
                  prevSelectedItemData && {
                    ...prevSelectedItemData,
                    notes: newNotes
                  }
              )
            }
          />
          {handleDeleteTask && (
            <DeleteTaskModal
              isOpen={isDeleteTaskModalOpen}
              onCancelClick={() => {
                setIsDeleteTaskModalOpen(false);
              }}
              onSubmit={handleDeleteTask}
            />
          )}
        </form>
      )}
    </Modal>
  );
};
