import {
  ITaskTypePayload,
  ITaskTypeResponse,
  ITaskTypesResponse
} from 'types/taskType';
import request from './index';
import toErrorWithMessage from 'utilities/getErrorMessage';
import { serialize } from 'object-to-formdata';

export const getTaskType = (id: number) => {
  try {
    return request<ITaskTypeResponse>({
      options: {
        url: `/task_types/${id}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const getTaskTypes = () => {
  try {
    return request<ITaskTypesResponse>({
      options: {
        url: '/task_types',
        method: 'get'
      }
    });
  } catch (e) {
    return { data: [], pagination: {}, error: toErrorWithMessage(e) };
  }
};

export const createTaskType = (data: ITaskTypePayload) => {
  try {
    return request<ITaskTypeResponse>({
      options: {
        url: `/task_types`,
        method: 'post',
        data: serialize(data)
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const editTaskType = (id: number, data: ITaskTypePayload) => {
  try {
    return request<ITaskTypeResponse>({
      options: {
        url: `/task_types/${id}`,
        method: 'patch',
        data: serialize(data, { allowEmptyArrays: true })
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const deleteTaskType = (id: number) => {
  try {
    return request<{}>({
      options: {
        url: `/task_types/${id}`,
        method: 'delete'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
