import { IOption } from 'components/_form/Select/Select';
import React, { useContext, useState } from 'react';

export interface IAdminTaskTypeContext {
  userFunctionsList: IOption[];
  setUserFunctionsList: React.Dispatch<React.SetStateAction<IOption[]>>;
}

const AdminTaskTypeContext = React.createContext<
  IAdminTaskTypeContext | undefined
>(undefined);

export const AdminTaskTypeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [userFunctionsList, setUserFunctionsList] = useState<IOption[]>([]);

  return (
    <AdminTaskTypeContext.Provider
      value={{
        userFunctionsList,
        setUserFunctionsList
      }}
    >
      {children}
    </AdminTaskTypeContext.Provider>
  );
};

export const useAdminTaskTypeContext = () => {
  const context = useContext(AdminTaskTypeContext);
  if (!context) {
    throw new Error(
      'useAdminTaskTypeContext must be inside a AdminTaskTypeContextProvider with a value'
    );
  }
  return context;
};
