import { Flex } from 'components';
import { TableHeader } from './TaskTypes.styled';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/_form';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { editTaskType, getTaskTypes } from 'api/taskTypes';
import { ITaskType } from 'types/taskType';
import { Row } from './components/Row/Row';
import { AddTaskTypeForm } from './components/Form/AddTaskTypeForm/AddTaskTypeForm';
import { EditTaskTypeForm } from './components/Form/EditTaskTypeForm/EditTaskTypeForm';
import { toast } from 'react-toastify';
import { getUserFunctions } from 'api/userFunctions';
import { useAdminTaskTypeContext } from 'contexts/AdminTaskTypeContext';

export const TaskTypes = () => {
  const [isAddFormOpen, setAddIsFormOpen] = useState(false);
  const [taskTypes, setTaskTypes] = useState<ITaskType[]>([]);
  const [editingTaskTypeId, setEditingTaskTypeId] = useState<number>();
  const [parentTaskTypeId, setParentTaskTypeId] = useState<number>();

  const { setUserFunctionsList } = useAdminTaskTypeContext();

  const { t } = useTranslation();

  const handleGetTaskTypes = async () => {
    const taskTypesRes = await getTaskTypes();

    if ('error' in taskTypesRes) {
      return;
    }

    setTaskTypes(taskTypesRes.data);
  };

  const handleChangeTaskTypeOrder = async (
    taskType: ITaskType,
    type: 'increase' | 'decrease'
  ) => {
    const wantedOrder =
      type === 'increase' ? taskType.order + 1 : taskType.order - 1;

    const editTaskTypeRes = await editTaskType(taskType.id, {
      task_type: {
        order: wantedOrder
      }
    });

    if ('error' in editTaskTypeRes) {
      toast.error(editTaskTypeRes.error.message);
      return;
    }

    setTaskTypes((prevTaskTypes) =>
      prevTaskTypes.map((prevTaskType) =>
        prevTaskType.id === editTaskTypeRes.data.id
          ? editTaskTypeRes.data
          : prevTaskType
      )
    );

    const taskTypeWithWantedOrder = taskTypes.find(
      ({ parent_id, order }) =>
        parent_id === taskType.parent_id && order === wantedOrder
    );

    if (taskTypeWithWantedOrder) {
      const editTaskTypeWithReplacedOrderRes = await editTaskType(
        taskTypeWithWantedOrder.id,
        {
          task_type: {
            order: taskType.order
          }
        }
      );

      if ('error' in editTaskTypeWithReplacedOrderRes) {
        toast.error(editTaskTypeWithReplacedOrderRes.error.message);
        return;
      }

      setTaskTypes((prevTaskTypes) =>
        prevTaskTypes.map((prevTaskType) =>
          prevTaskType.id === editTaskTypeWithReplacedOrderRes.data.id
            ? editTaskTypeWithReplacedOrderRes.data
            : prevTaskType
        )
      );
    }
  };

  const handleGetUserFunctionsList = useCallback(async () => {
    const { data } = await getUserFunctions();

    setUserFunctionsList(
      data.map((functionItem) => ({
        value: functionItem.id,
        label: functionItem.name
      }))
    );
  }, []);

  useEffect(() => {
    handleGetTaskTypes();
    handleGetUserFunctionsList();
  }, []);

  return (
    <Flex flexDirection="column" gap="5px">
      <Flex justifyContent="end" mb={2}>
        <Button
          variant="eucalyptus"
          onClick={() => {
            setAddIsFormOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faCirclePlus} /> {t('common.add')}
        </Button>
      </Flex>

      <Flex overflow="auto" width="100%">
        <Flex flexDirection="column" gap="5px" minWidth="1200px" width="100%">
          <Flex>
            <TableHeader width="8%">
              {t('administrationTasksView.taskTypeTableHeaders.active')}
            </TableHeader>
            <TableHeader width="28%">
              {t('administrationTasksView.taskTypeTableHeaders.taskType')}
            </TableHeader>
            <TableHeader width="12%">
              {t('administrationTasksView.taskTypeTableHeaders.status')}
            </TableHeader>
            <TableHeader width="12%">
              {t(
                'administrationTasksView.taskTypeTableHeaders.requiresApproval'
              )}
            </TableHeader>
            <TableHeader width="12%">
              {t('administrationTasksView.taskTypeTableHeaders.stepsToApprove')}
            </TableHeader>
            <TableHeader width="17%">
              {t('administrationTasksView.taskTypeTableHeaders.whoApproves')}
            </TableHeader>
            <TableHeader width="11%"></TableHeader>
          </Flex>

          {taskTypes
            .sort((a, b) => (a.order || 0) - (b.order || 0))
            .map((taskType) => {
              const subList = taskTypes.filter(
                ({ parent_id }) => parent_id === taskType.id
              );

              return (
                !taskType.parent_id && (
                  <React.Fragment key={`taskTypesList-${taskType.id}`}>
                    <Row
                      taskType={taskType}
                      listLength={
                        taskTypes.filter(({ parent_id }) => !parent_id).length
                      }
                      setEditingTaskTypeId={setEditingTaskTypeId}
                      setParentTaskTypeId={setParentTaskTypeId}
                      handleChangeTaskTypeOrder={handleChangeTaskTypeOrder}
                    />

                    {subList.map((subTaskType) => (
                      <Row
                        key={`taskTypesList-${taskType.id}-subTaskTypes-${subTaskType.id}`}
                        taskType={subTaskType}
                        listLength={subList.length}
                        isSubRow
                        setEditingTaskTypeId={setEditingTaskTypeId}
                        setParentTaskTypeId={setParentTaskTypeId}
                        handleChangeTaskTypeOrder={handleChangeTaskTypeOrder}
                      />
                    ))}
                  </React.Fragment>
                )
              );
            })}
        </Flex>
      </Flex>

      <AddTaskTypeForm
        parentTaskTypeId={parentTaskTypeId}
        isOpen={isAddFormOpen || !!parentTaskTypeId}
        taskTypes={taskTypes}
        onCancelClick={() => {
          setAddIsFormOpen(false);
          setParentTaskTypeId(undefined);
        }}
        onSuccess={(newTaskType) => {
          setTaskTypes((prevTaskTypes) => [...prevTaskTypes, newTaskType]);
          setAddIsFormOpen(false);
          setParentTaskTypeId(undefined);
        }}
      />

      {editingTaskTypeId && (
        <EditTaskTypeForm
          isOpen={!!editingTaskTypeId}
          editingTaskTypeId={editingTaskTypeId}
          onCancelClick={() => {
            setEditingTaskTypeId(undefined);
          }}
          onSubmitSuccess={() => {
            handleGetTaskTypes();
            setEditingTaskTypeId(undefined);
          }}
          onDeleteSuccess={() => {
            handleGetTaskTypes();
            setEditingTaskTypeId(undefined);
          }}
        />
      )}
    </Flex>
  );
};
