import {
  DescriptionContentWrapper,
  PaddingWrapper,
  ResultHeading,
  StyledFlex,
  StyledMDEditor,
  SubGroupHeader
} from './AuditCriteriaTable.styled';
import { Flex } from 'components';
import {
  Cell,
  FixedFontSizeWrapper,
  GradingScaleInformationBox,
  GradingScaleInformationRowWrapper,
  Numbers,
  PointerWrapper,
  Rotated
} from 'views/EvaluationsView/components/EvaluationModal/EvaluationModal.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLightbulb,
  faMapLocationDot,
  faRankingStar
} from '@fortawesome/free-solid-svg-icons';

import {
  faLightbulb as regularFaLightbulb,
  faSquareMinus,
  faSquarePlus
} from '@fortawesome/free-regular-svg-icons';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { theme } from 'styles';
import { HeaderCell } from 'views/EvaluationsView/components/EvaluationModal/components/HeaderCell';
import { patchAudit } from 'api/audits';
import {
  IAuditFormCriterion,
  IAuditGroup,
  IDetailedAudit,
  IDetailedAuditCriterion
} from 'types/audits';
import { useTranslation } from 'react-i18next';
import { TableScore } from 'types/evaluations';
import { AuditCriteriaNumberCell } from '../AuditCriteriaNumberCell/AuditCriteriaNumberCell';
import { PartialAudit } from 'views/AdministrationView/views/AuditsView/components/AuditFormForm/components/AuditFormPreview/AuditFormPreview';
import { P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { countGroups } from 'utilities/audit/countGroups';

export interface ChangeComponentOrder {
  itemId: string;
  direction: 'up' | 'down';
}

export interface Item {
  number: string;
  description: string;
  scores: TableScore[];
  gradingScale: string;
  guidelines?: string;
  examples: boolean;
}

interface BaseProps {
  rowHeight: number;
  gradingScaleSwitch: boolean;
  hintSwitch: boolean;
  fontSize: number;
  textInCellLineHeight: number;
  flattenCriteria?: Partial<IAuditFormCriterion>[];
  onFinishAuditSuccess?: (newAudit: IDetailedAudit) => void;
}

interface PartialProps extends BaseProps {
  isFullData: false;
  audit: PartialAudit | undefined;
  setAudit: Dispatch<SetStateAction<PartialAudit | undefined>>;
}

interface Props extends BaseProps {
  isFullData: true;
  audit: IDetailedAudit | undefined;
  setAudit: Dispatch<SetStateAction<IDetailedAudit | undefined>>;
}

const AuditCriteriaTable: React.FC<PartialProps | Props> = ({
  audit,
  setAudit,
  rowHeight,
  gradingScaleSwitch,
  hintSwitch,
  fontSize,
  textInCellLineHeight,
  isFullData,
  flattenCriteria,
  onFinishAuditSuccess
}) => {
  const [gradingScaleOpen, setGradingScaleOpen] = useState(true);
  const [componentOrder, setComponentOrder] = useState<string[]>([]);
  const [practiceEvaluationActive, setPracticeEvaluationActive] =
    useState(false);
  const [guidelinesOpen, setGuidelinesOpen] = useState(true);
  const [showCriteriaModal, setShowCriteriaModal] = useState(false);
  const [criteriaModalTitle, setCriteriaModalTitle] = useState('');
  const [clickedCriterion, setClickedCriterion] =
    useState<Partial<IDetailedAuditCriterion>>();

  const groupsNumber = countGroups(audit?.audit_form.groups || []);

  const { t } = useTranslation();

  const changeComponentOrder = useCallback(
    ({ itemId, direction }: ChangeComponentOrder) => {
      const parsedDirection = direction === 'up' ? -1 : 1;
      const index = componentOrder.indexOf(itemId);
      const newIndex = index + parsedDirection;
      const newComponentOrder = [...componentOrder];
      const temp = newComponentOrder[index];

      newComponentOrder[index] = newComponentOrder[newIndex];
      newComponentOrder[newIndex] = temp;
      setComponentOrder(newComponentOrder);
    },
    [componentOrder]
  );

  const handlePatchAuditScore = useCallback(
    async (buttonId: number | string, criterionId: number | string) => {
      if (!isFullData) {
        setAudit((prevAudit) =>
          prevAudit
            ? {
                ...prevAudit,
                scores: [
                  ...(prevAudit.scores?.filter(
                    (score) => score.audit_criterion_id !== criterionId
                  ) || []),
                  {
                    audit_criterion_id: criterionId,
                    rating_button_id: buttonId
                  }
                ]
              }
            : undefined
        );
        return;
      }

      if (
        audit &&
        audit.id &&
        typeof buttonId !== 'string' &&
        typeof criterionId !== 'string'
      ) {
        const patchedAudit = await patchAudit(audit.id, {
          audit: {
            scores_attributes: [
              {
                audit_criterion_id: criterionId,
                rating_button_id: buttonId
              }
            ]
          }
        });

        setAudit(patchedAudit.data);
      }
    },
    [audit, setAudit]
  );

  const groupRowHeight = 40;

  const heightOfTable =
    (flattenCriteria?.length || 1) * rowHeight + groupsNumber * groupRowHeight;

  const table = useCallback(
    (
      criteria: Partial<IDetailedAuditCriterion>[] | IDetailedAuditCriterion[],
      options?: {
        hideHeaders?: boolean;
        prefix?: string;
        renderDescriptions?: boolean;
      }
    ) => {
      return {
        nr: (
          <StyledFlex width={['10%', '5%']} flexDirection="column">
            {!options?.hideHeaders && (
              <FixedFontSizeWrapper>
                <HeaderCell>{t('evaluationsView.nr')}</HeaderCell>
              </FixedFontSizeWrapper>
            )}

            {criteria?.map((item) => (
              <Cell
                key={item.id + t('evaluationsView.nr')}
                height={`${rowHeight}px`}
                alignItems="center"
                justifyContent="center"
              >
                <Rotated>
                  {item.nr
                    ? options?.prefix
                      ? `${options?.prefix}(${item.nr})`
                      : item.nr
                    : ''}
                </Rotated>
              </Cell>
            ))}
          </StyledFlex>
        ),
        criteria: (
          <StyledFlex
            width={
              !guidelinesOpen && !gradingScaleOpen ? '70%' : ['60%', '50%']
            }
            flexDirection="column"
          >
            {!options?.hideHeaders && (
              <FixedFontSizeWrapper>
                <HeaderCell>
                  {t('evaluationsView.evaluationCriteria')}
                </HeaderCell>
              </FixedFontSizeWrapper>
            )}

            {criteria?.map((item) => (
              <Cell
                key={item.id + 'criteria'}
                height={`${rowHeight}px`}
                alignItems="center"
              >
                {item.content}
              </Cell>
            ))}
          </StyledFlex>
        ),
        evaluation: (
          <StyledFlex width={['30%', '15%']} flexDirection="column">
            {!options?.hideHeaders && (
              <FixedFontSizeWrapper>
                <HeaderCell>{t('evaluationsView.evaluation')}</HeaderCell>
              </FixedFontSizeWrapper>
            )}

            {audit &&
              criteria?.map((item) => {
                const scores = audit.scores;

                const buttonIdForScore = scores?.find(
                  (score) => score.audit_criterion_id === item.id
                )?.rating_button_id;

                const selectedButton = audit.used_rating_scale?.buttons.find(
                  (button) => button.id === buttonIdForScore
                );

                return (
                  <Flex key={item.id + 'evaluation'}>
                    <Cell
                      height={`${rowHeight}px`}
                      alignItems="center"
                      minWidth="60px"
                    >
                      {audit && item.id && (
                        <AuditCriteriaNumberCell
                          criterion={item}
                          onChooseScore={handlePatchAuditScore}
                          selectedButton={selectedButton}
                          cellHeight={rowHeight}
                          ratingScale={audit.used_rating_scale}
                          onClick={() => {
                            setClickedCriterion(item);
                          }}
                          onClickOutside={() => {
                            setClickedCriterion(undefined);
                          }}
                        />
                      )}
                    </Cell>
                    <Cell height={`${rowHeight}px`} alignItems="center"></Cell>
                  </Flex>
                );
              })}
          </StyledFlex>
        ),
        gradingScale: (
          <StyledFlex
            width={gradingScaleOpen ? '15%' : '5%'}
            flexDirection="column"
            display={['none', 'flex']}
          >
            {!options?.hideHeaders && (
              <HeaderCell>
                <FixedFontSizeWrapper>
                  {gradingScaleOpen ? (
                    t('evaluationsView.gradingScale')
                  ) : (
                    <FontAwesomeIcon icon={faRankingStar} />
                  )}
                </FixedFontSizeWrapper>

                <Flex p={2}>
                  <button
                    type="button"
                    onClick={() => {
                      setGradingScaleOpen(
                        (prevGradingScaleOpen) => !prevGradingScaleOpen
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={gradingScaleOpen ? faSquareMinus : faSquarePlus}
                    />
                  </button>
                </Flex>
              </HeaderCell>
            )}

            {criteria.length ? (
              <>
                {gradingScaleOpen && options?.renderDescriptions ? (
                  <DescriptionContentWrapper
                    data-color-mode="light"
                    mb={`-${heightOfTable - 80}px`}
                    height={`${heightOfTable}px`}
                  >
                    <StyledMDEditor
                      height="100%"
                      enableScroll={false}
                      visibleDragbar={false}
                      hideToolbar={true}
                      preview={'preview'}
                      value={clickedCriterion?.scale_description || ''}
                    />
                  </DescriptionContentWrapper>
                ) : (
                  criteria.map((item) => (
                    <Cell
                      key={`${item.scale_description}-${item.nr}`}
                      height={`${rowHeight}px`}
                      justifyContent="center"
                    >
                      <FontAwesomeIcon
                        icon={faRankingStar}
                        size="xl"
                        color={
                          item.scale_description
                            ? theme.palette.accent.yellow
                            : 'grey'
                        }
                      />
                    </Cell>
                  ))
                )}
              </>
            ) : (
              <></>
            )}
          </StyledFlex>
        ),
        guidelines: (
          <StyledFlex
            width={guidelinesOpen ? '15%' : '5%'}
            flexDirection="column"
            display={['none', 'flex']}
          >
            {!options?.hideHeaders && (
              <HeaderCell>
                <FixedFontSizeWrapper>
                  {guidelinesOpen ? (
                    t('evaluationsView.guidelines')
                  ) : (
                    <FontAwesomeIcon icon={faMapLocationDot} />
                  )}
                </FixedFontSizeWrapper>

                <Flex p={2}>
                  <button
                    type="button"
                    onClick={() => {
                      setGuidelinesOpen(
                        (prevGuidelinesOpen) => !prevGuidelinesOpen
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={guidelinesOpen ? faSquareMinus : faSquarePlus}
                    />
                  </button>
                </Flex>
              </HeaderCell>
            )}
            {criteria.length ? (
              <>
                {guidelinesOpen && options?.renderDescriptions ? (
                  <DescriptionContentWrapper
                    data-color-mode="light"
                    mb={`-${heightOfTable - 80}px`}
                    height={`${heightOfTable}px`}
                  >
                    <StyledMDEditor
                      height="100%"
                      enableScroll={false}
                      visibleDragbar={false}
                      hideToolbar={true}
                      preview={'preview'}
                      value={clickedCriterion?.tips || ''}
                    />
                  </DescriptionContentWrapper>
                ) : (
                  criteria.map((item) => (
                    <Cell
                      key={`${item.scale_description}-${item.nr}`}
                      height={`${rowHeight}px`}
                      justifyContent="center"
                    >
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        size="xl"
                        color={
                          item.scale_description
                            ? theme.palette.accent.yellow
                            : 'grey'
                        }
                      />
                    </Cell>
                  ))
                )}
              </>
            ) : (
              <></>
            )}
          </StyledFlex>
        ),
        bulbs: (
          <StyledFlex
            flexDirection="column"
            width="4%"
            display={['none', 'flex']}
          >
            {!options?.hideHeaders && (
              <HeaderCell>
                <FixedFontSizeWrapper>
                  <FontAwesomeIcon size="2xl" icon={faLightbulb} />
                </FixedFontSizeWrapper>
              </HeaderCell>
            )}

            {criteria.map((item, i) => (
              <Cell
                key={`examples-${i}`}
                height={`${rowHeight}px`}
                justifyContent="center"
              >
                <FixedFontSizeWrapper>
                  <PointerWrapper
                    onClick={() => {
                      setShowCriteriaModal(true);
                      setCriteriaModalTitle('exampleForCriterion');
                    }}
                  >
                    <FontAwesomeIcon
                      size="2xl"
                      color={theme.palette.accent.yellow}
                      icon={regularFaLightbulb}
                    />
                  </PointerWrapper>
                </FixedFontSizeWrapper>
              </Cell>
            ))}
          </StyledFlex>
        )
      };
    },
    [
      audit,
      fontSize,
      gradingScaleOpen,
      guidelinesOpen,
      handlePatchAuditScore,
      hintSwitch,
      rowHeight,
      t,
      textInCellLineHeight,
      clickedCriterion?.scale_description
    ]
  );

  const initComponentOrder = useCallback(() => {
    setComponentOrder(Object.keys(table(audit?.audit_form.criteria || [])));
  }, [JSON.stringify(audit?.audit_form.criteria), table]);

  useEffect(() => {
    initComponentOrder();
  }, [initComponentOrder]);

  const auditResult =
    audit?.scores?.reduce((acc, score) => {
      const button = audit?.used_rating_scale?.buttons.find(
        (button) => button.id === score.rating_button_id
      );

      const criterion = flattenCriteria?.find(
        (flattenCriterion) => flattenCriterion.id === score.audit_criterion_id
      );

      return acc + (button?.value || 0) * (criterion?.weight || 1);
    }, 0) || 0;

  const maxButtonValue = Math.max(
    ...(audit?.used_rating_scale?.buttons.map((button) => button.value) || [])
  );

  const fullPossibleAuditResult =
    flattenCriteria?.reduce((acc, flattenCriterion) => {
      return acc + (maxButtonValue || 0) * (flattenCriterion?.weight || 1);
    }, 0) || 0;

  const percentAuditResult =
    Math.round((auditResult / fullPossibleAuditResult) * 100 * 100) / 100;

  const renderGroups = (groups: IAuditGroup[], prefix = '') => {
    return (
      <Flex flexDirection="column">
        {groups?.map((group, index) => (
          <Flex key={`AuditCriteriaTable-${group.id}`} flexDirection="column">
            <Flex width="100%">
              <SubGroupHeader width="5%" p={2}>{`${prefix}${
                index + 1
              }`}</SubGroupHeader>
              <SubGroupHeader width="95%" p={2}>
                <P variant="h3">{group.name}</P>
              </SubGroupHeader>
            </Flex>

            <Flex>
              {componentOrder?.map((component: string) => {
                return (
                  <Fragment key={`AuditCriteriaTable-${group.id}-${component}`}>
                    {
                      table(group.criteria || [], {
                        hideHeaders: true,
                        prefix: `${prefix}${index + 1}.`,
                        renderDescriptions: false
                      })[component as keyof typeof table]
                    }
                  </Fragment>
                );
              })}
            </Flex>

            {renderGroups(group.groups, `${prefix}${index + 1}.`)}
          </Flex>
        ))}
      </Flex>
    );
  };

  const handleFinishAudit = async () => {
    if (!isFullData) {
      return;
    }

    if (audit && audit.id) {
      const patchedAudit = await patchAudit(audit.id, {
        audit: {
          state: 'finished',
          result: percentAuditResult,
          total_points: auditResult,
          maximum_total_points: fullPossibleAuditResult
        }
      });

      setAudit(patchedAudit.data);

      onFinishAuditSuccess?.(patchedAudit.data);
    }
  };

  return (
    <>
      <PaddingWrapper>
        <StyledFlex>
          {componentOrder?.map((component: string) => {
            return (
              <Fragment key={`AuditCriteriaTable-${component}`}>
                {
                  table(audit?.audit_form.criteria || [], {
                    renderDescriptions: true
                  })[component as keyof typeof table]
                }
              </Fragment>
            );
          })}
        </StyledFlex>

        {renderGroups(audit?.audit_form?.groups || [])}
      </PaddingWrapper>

      <Flex
        pl={['5%', '40%']}
        mt={3}
        mb={4}
        alignItems="center"
        flexDirection={['column', 'row']}
      >
        <Flex mb={[2, 0]}>
          <ResultHeading variant={['h3', 'h2']} mr={[2, 5]}>
            {t('auditsView.auditResult')}
          </ResultHeading>
          <P variant={['h3', 'h2']} fontWeight={700} mr={[1, 3]}>
            {auditResult}
          </P>
          <P variant={['h3', 'h2']} fontWeight={700} mr={[2, '60px']}>
            <Flex gap="6px">
              <span>{t('auditsView.from')}</span>
              <span>{fullPossibleAuditResult}</span>
              <span>{t('auditsView.pnt')}</span>
            </Flex>
          </P>

          {percentAuditResult ? (
            <P
              mr={[1, 5]}
              variant={['h3', 'h2']}
              color={
                audit?.audit_form?.goal?.includes('%')
                  ? percentAuditResult <
                    Number(audit?.audit_form?.goal?.replace('%', ''))
                    ? 'red'
                    : 'green'
                  : auditResult < Number(audit?.audit_form?.goal)
                  ? 'red'
                  : 'green'
              }
            >
              {percentAuditResult}%
            </P>
          ) : (
            ''
          )}
        </Flex>

        <Button
          type="button"
          variant="green"
          onClick={handleFinishAudit}
          disabled={
            audit?.audit_form?.enforce_completeness
              ? !flattenCriteria?.every((criterion) =>
                  audit?.scores?.find(
                    (score) => criterion.id === score.audit_criterion_id
                  )
                )
              : false
          }
        >
          <Flex px={48}>{t('auditsView.finish')}</Flex>
        </Button>
      </Flex>

      {gradingScaleSwitch && (
        <GradingScaleInformationBox>
          <GradingScaleInformationRowWrapper>
            <Numbers>3 -&nbsp;</Numbers>
            {t(`evaluationsView.gradingScaleDescription.3`)}
          </GradingScaleInformationRowWrapper>
          <GradingScaleInformationRowWrapper>
            <Numbers>1 -&nbsp;</Numbers>
            {t('evaluationsView.gradingScaleDescription.1')}
          </GradingScaleInformationRowWrapper>
          <GradingScaleInformationRowWrapper>
            <Numbers>0 -&nbsp;</Numbers>
            {t('evaluationsView.gradingScaleDescription.0')}
          </GradingScaleInformationRowWrapper>
        </GradingScaleInformationBox>
      )}
    </>
  );
};

export default AuditCriteriaTable;
