import { Flex } from 'components';
import { TableHeader } from '../../../../../TaskTypes.styled';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ITaskTypePayload } from 'types/taskType';
import { useAdminTaskTypeContext } from 'contexts/AdminTaskTypeContext';
import { useTranslation } from 'react-i18next';
import { IRealizationProgress } from 'types/realizationProgresses';
import { Button, Checkbox, FieldLabel, Select } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faPlusCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { StepRow } from './ApprovingStepsTable.styled';
import { TableCell } from '../../../../Row/Row.styled';
import { handlePrevState } from 'utilities/handlePrevState';

interface Props {
  realizationProgresses: IRealizationProgress[];
}

export const ApprovingStepsTable: React.FC<Props> = ({
  realizationProgresses
}) => {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext<ITaskTypePayload['task_type']>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `functions_approving_steps_attributes`
  });

  const { userFunctionsList } = useAdminTaskTypeContext();

  const { t } = useTranslation();

  const handleAppendApprovingStep = () => {
    append({
      realization_progress_approval_steps_attributes: realizationProgresses.map(
        (realizationProgress) => ({
          realization_progress_id: realizationProgress.id,
          steps_to_approve: []
        })
      )
    });
  };

  return (
    <Flex overflow="auto" width="100%">
      <Flex flexDirection="column" gap="5px" minWidth="700px" width="100%">
        <Flex>
          <TableHeader width="42%">
            {t(
              'administrationTasksView.taskTypeApprovalStepsTableHeaders.approverRole'
            )}
          </TableHeader>
          <TableHeader width="42%">
            {t(
              'administrationTasksView.taskTypeApprovalStepsTableHeaders.approveSteps'
            )}
          </TableHeader>
          <TableHeader width="16%">
            <Button variant="green" onClick={handleAppendApprovingStep}>
              <Flex gap="4px">
                <FontAwesomeIcon icon={faPlusCircle} size="xl" />
                {t('common.add')}
              </Flex>
            </Button>
          </TableHeader>
        </Flex>

        {fields.map((field, index) => (
          <StepRow key={`steps-fields-${field.id}`}>
            <TableCell width="42%" justifyContent="center">
              <Controller
                control={control}
                name={`functions_approving_steps_attributes.${index}.function_id`}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={userFunctionsList.filter(
                      (userFunction) =>
                        !watch('functions_approving_steps_attributes')?.find(
                          ({ function_id }) =>
                            function_id?.toString() ===
                            userFunction.value?.toString()
                        )
                    )}
                    selectedOptions={userFunctionsList.find(
                      (userFunction) =>
                        userFunction.value?.toString() === value?.toString()
                    )}
                    onChange={(selectedOption) => {
                      if (!Array.isArray(selectedOption)) {
                        onChange(selectedOption.value);
                      }
                    }}
                    withoutMargin
                    error={t(
                      errors.functions_approving_steps_attributes?.[index]
                        ?.function_id?.message || ''
                    )}
                  />
                )}
              />
            </TableCell>
            <Flex width="42%">
              {realizationProgresses.map((realizationProgress) => (
                <TableCell
                  key={`steps-${field.id}-realizationProgresses-${realizationProgress.id}`}
                >
                  {realizationProgress.kind.split('').map((letter) => (
                    <Controller
                      key={`steps-${field.id}-realizationProgresses-${realizationProgress.id}-${letter}`}
                      control={control}
                      name={`functions_approving_steps_attributes.${index}.realization_progress_approval_steps_attributes`}
                      render={({
                        field: { value: approvalSteps, onChange }
                      }) => (
                        <Flex flexDirection="column">
                          <FieldLabel centered width="70%">
                            {letter}
                          </FieldLabel>
                          <Checkbox
                            checked={
                              !!approvalSteps?.find(
                                (approvalStep) =>
                                  approvalStep.realization_progress_id ===
                                    realizationProgress.id &&
                                  approvalStep.steps_to_approve.includes(letter)
                              )
                            }
                            onChange={() => {
                              onChange(
                                approvalSteps?.map((approvalStep) =>
                                  approvalStep.realization_progress_id ===
                                  realizationProgress.id
                                    ? {
                                        ...approvalStep,
                                        steps_to_approve: handlePrevState(
                                          approvalStep.steps_to_approve,
                                          letter
                                        )
                                      }
                                    : approvalStep
                                )
                              );
                            }}
                          />
                        </Flex>
                      )}
                    />
                  ))}
                </TableCell>
              ))}
            </Flex>
            <TableCell width="16%" gap="5px">
              <Button
                variant="eucalyptus"
                icon={<FontAwesomeIcon icon={faTrash} size="xl" />}
                onClick={() => {
                  remove(index);
                }}
              />
              <Flex flexDirection="column">
                <Button
                  bordered
                  transparent
                  icon={<FontAwesomeIcon icon={faChevronUp} size="xl" />}
                  minHeight="17px"
                  onClick={() => {}}
                />
                <Button
                  bordered
                  transparent
                  icon={<FontAwesomeIcon icon={faChevronDown} size="xl" />}
                  minHeight="17px"
                  onClick={() => {}}
                />
              </Flex>
            </TableCell>
          </StepRow>
        ))}
      </Flex>
    </Flex>
  );
};
