import {
  faBullseye,
  faFileLines,
  faLockOpen,
  faPeopleGroup,
  faSquarePen
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Flex, Table } from 'components';
import { ColumnsOptions } from 'components/Table/components/ColumnsOptions';
import { Checkbox } from 'components/_form';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faFileLines as faRegluarFileLines } from '@fortawesome/free-regular-svg-icons';
import { typeColorsDict } from 'styles/theme';
import { EditAuditModal } from './AuditFormModal/EditAuditModal';
import { getAudit } from 'api/audits';
import { toast } from 'react-toastify';
import { Loader } from 'components/Loader/Loader';
import { OptionButtons } from './OptionButtons/OptionButtons';
import { format } from 'date-fns';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { dateHoursFormat } from 'constants/dateFormats/dateHourFormat';

interface Props {}

const Audits: React.FC<Props> = () => {
  const {
    loading,
    audits,
    handleLoadAudits,
    setSelectedAudit,
    selectedAudits,
    setSelectedAudits,
    selectedAuditId,
    setSelectedAuditId,
    isEditAuditModalOpen,
    setIsEditAuditModalOpen,
    auditsPage,
    setAuditsPage,
    auditsPerPage,
    setAuditsPerPage,
    auditsPaginationTotal
  } = useEvaluationContext();
  const [hiddenItems, setHiddenItems] = useState<string[]>(['type']);
  const [loadingAuditIds, setLoadingAuditIds] = useState<number[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    handleLoadAudits();
  }, [handleLoadAudits]);

  const handleSelectAudit = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, auditId: number) => {
      try {
        setLoadingAuditIds((prevLoadingAuditIds) => [
          ...prevLoadingAuditIds,
          auditId
        ]);
        if (e.target.checked) {
          const { data: audit } = await getAudit(auditId);

          setSelectedAudits((prevSelectedAudits) => [
            ...prevSelectedAudits,
            audit
          ]);
          setSelectedAudit(audit);
        } else {
          setSelectedAudits((prevSelectedAudits) => [
            ...prevSelectedAudits.filter(
              (prevSelectedAudit) => prevSelectedAudit.id !== auditId
            )
          ]);
        }
        setLoadingAuditIds((prevLoadingAuditIds) =>
          prevLoadingAuditIds.filter(
            (loadingAuditId) => loadingAuditId !== auditId
          )
        );
      } catch (e) {
        toast.error('anErrorOccurred');
      }
    },
    [setSelectedAudit, setSelectedAudits]
  );

  const parsedData = audits.map((audit) => {
    return {
      checkbox: loadingAuditIds.includes(audit.id) ? (
        <Loader />
      ) : (
        <Checkbox
          unCheckedIconDisplay
          variant="secondary"
          checked={!!selectedAudits.find(({ id }) => audit.id === id)}
          onChange={(e) => {
            handleSelectAudit(e, audit.id);
          }}
        />
      ),
      auditForm: audit.audit_form?.name,
      type: (
        <FontAwesomeIcon
          icon={
            audit.evaluation_type === 'normal'
              ? faRegluarFileLines
              : faFileLines
          }
          size="2xl"
          color={typeColorsDict[audit.evaluation_type]}
        />
      ),
      number: audit.number,
      auditDate:
        audit.completion_time &&
        format(new Date(audit.completion_time), dateFormat),
      deadLine:
        audit.planned_completion_date &&
        format(new Date(audit.planned_completion_date), dateFormat),
      lastUpdate:
        audit.updated_at && format(new Date(audit.updated_at), dateHoursFormat),
      evaluator: (
        <>
          <Flex justifyContent="flex-start" alignItems="center">
            <Avatar size="extra-small" />
            <Box ml={2}>{audit.author?.username}</Box>
          </Flex>
        </>
      ),
      result: `${audit.result || '0.00'}%`,
      status: <FontAwesomeIcon icon={faLockOpen} />,
      group: audit.teams?.length && audit.teams[0].name,
      name: audit.name,
      version: audit.version,
      options: <OptionButtons audit={audit} />
    };
  });

  const switchableItems = [
    'auditForm',
    'type',
    'number',
    'auditDate',
    'deadLine',
    'lastUpdate',
    'evaluator',
    'result',
    'status',
    'team',
    'name',
    'version'
  ];

  const header: (string | JSX.Element | null)[] = [
    '',
    t('auditsView.tableHeaders.auditForm'),
    t('auditsView.tableHeaders.type'),
    t('auditsView.tableHeaders.number'),
    t('auditsView.tableHeaders.auditDate'),
    t('auditsView.tableHeaders.deadLine'),
    t('auditsView.tableHeaders.lastUpdate'),
    t('auditsView.tableHeaders.auditsLeader'),
    <FontAwesomeIcon key="bullseye" icon={faBullseye} />,
    <FontAwesomeIcon key="squarePen" icon={faSquarePen} />,
    <FontAwesomeIcon key="peopleGroup" icon={faPeopleGroup} />,
    t('auditsView.tableHeaders.name'),
    t('auditsView.tableHeaders.version'),
    <Flex key="options" ml="auto">
      <ColumnsOptions
        switchableItems={switchableItems}
        hiddenItems={hiddenItems}
        setHiddenItems={setHiddenItems}
        labelPrefix="auditsView.tableHeaders."
      />
    </Flex>
  ];

  return (
    <div>
      {loading ? (
        <Flex justifyContent="center" py={4}>
          <Loader />
        </Flex>
      ) : (
        <Table
          header={header}
          data={parsedData}
          leftFixedKeys={[0, 1]}
          hiddenItems={hiddenItems}
          onClickRow={(audit) => {}}
          pagination={{
            page: auditsPage,
            setPage: setAuditsPage,
            perPage: auditsPerPage,
            total: auditsPaginationTotal,
            setPerPage: setAuditsPerPage
          }}
        />
      )}

      <EditAuditModal
        isOpen={isEditAuditModalOpen}
        onCancelClick={() => setIsEditAuditModalOpen(false)}
        auditId={selectedAuditId}
      />
    </div>
  );
};

export default Audits;
