import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'styles';
import { AddAreaModal } from './AddAreaModal/AddAreaModal';
import { device } from 'styles/theme';

export const Wrapper = styled(Flex)<{ rotated?: boolean }>`
  height: 100px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column-reverse;
  font-weight: bold;
  justify-content: space-between;
  padding: 4px;
  background-color: ${({ theme }) => theme.palette.neutral.tableHeader};
  ${({ rotated }) =>
    rotated ? 'writing-mode: tb-rl; transform: rotate(-180deg);' : ''}

  @media ${device.laptop} {
    height: 60px;
  }
`;

const AddingTag = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 5px;
  justify-content: end;
  align-items: center;
  left: 5px;
  bottom: 0px;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border-left: 1px solid ${({ theme }) => theme.palette.accent.blue};
  border-right: 1px solid ${({ theme }) => theme.palette.accent.blue};
`;

const StyledFontAwesomeIcon: React.FC<FontAwesomeIconProps> = styled(
  FontAwesomeIcon
)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }) => theme.palette.accent.blue};
  border-radius: 100px;
`;

interface IHeaderCellProps {
  rotated?: boolean;
  children?: React.ReactNode;
  addingTag?: boolean;
  customStyles?: {};
}

export const HeaderCell: React.FC<IHeaderCellProps> = ({
  children,
  rotated,
  addingTag,
  customStyles
}) => {
  const [addingTagVisible, setAddingTagVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Wrapper
        rotated={rotated}
        onMouseEnter={() => setAddingTagVisible(true)}
        onMouseLeave={() => setAddingTagVisible(false)}
        style={customStyles}
      >
        {addingTag && addingTagVisible && (
          <AddingTag
            onClick={() => {
              setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
            }}
          >
            <StyledFontAwesomeIcon
              width="12px"
              height="12px"
              icon={faPlus}
              color={theme.palette.accent.blue}
              size="xs"
            />
          </AddingTag>
        )}

        {children}
      </Wrapper>

      <AddAreaModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};
